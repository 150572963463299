





































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FormValidationRules from "@/mixins/FormValidationRules";
const Users = namespace("Users");

@Component
export default class ResetPasswordDialog extends Mixins(FormValidationRules) {
  @Prop({ required: true }) value!: boolean;

  validPassReset: boolean = false;
  email: string = "";
  resetProcessConfirmed: boolean = false;

  get show(): boolean {
    console.log(this.value);
    return this.value;
  }
  set show(newValue: boolean) {
    if (!newValue) {
      this.resetProcessConfirmed = false;
    }
    this.$emit("input", newValue);
  }
  
  validatePassReset() {
    let validationSuccess = (this.$refs.form as Vue & {
      validate: () => boolean;
    }).validate();
    if (validationSuccess) {
      this.resetProcessConfirmed = true;
      this.forgotPassword({ email: this.email })
        .then(res => {
          this.EmailSuccess(res);
          this.$router.push("/");
        })
        .catch(e => this.EmailSendFailure(e));
    }
  }

  @Users.Action
  private forgotPassword!: (data: any) => Promise<any>;

  // need to be removed(fake success for Header UI)-------------------------
  @Users.Mutation
  private EmailSendFailure!: (err: any) => void;

  @Users.Mutation
  private EmailSuccess!: (response: any) => void;
}

