


















import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class DateSelect extends Vue {
  @Prop() selectDays!: any;
  days: Array<string> = ["1 year", "3 month", "1 month", "7 day", "1 day"];
}
