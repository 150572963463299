









































































import { Vue, Component } from "vue-property-decorator";
import Icon from "../../../../globalUsage/Icon.vue";
import TradingVue from "trading-vue-js";
import BuyCoins from "./buy/BuyCoins.vue";
import { namespace } from "vuex-class";
import { eventBus } from "@/main";
const Auth = namespace("Auth");
const Assets = namespace("Assets");
const Investments = namespace("Investments");
import { DataCube } from "trading-vue-js";
import { ASSETS_ENDPOINTS } from "../../../../../services/ApiEndpoints";

// import { namespace } from "vuex-class";
// const Auth = namespace("Auth");
// const Investments = namespace("Investments");

// import { io } from "socket.io-client";

// const options = {
//   path: "/socket.io/trading/get_instruments",
//   auth: {
//     token: "Bearer c8ab6d1d3b505d4fe1721465f5da7ab621d3cbf7",
//   },
//   Headers: {
//     Accept: "application/json",
//     "Content-Type": "application/json",
//     Authorization: "Bearer c8ab6d1d3b505d4fe1721465f5da7ab621d3cbf7",
//   },
// }; //Options object to pass into SocketIO

// const sockets = io("https://api-demo.fxcm.com:443", options);

@Component({
  components: { Icon, TradingVue, BuyCoins, DataCube },
})
export default class TradingPortal extends Vue {
  page: 1 = 1;
  // chart: Object = Data;
  singlePointPriceGraphLiveData = {
    chart: {
      type: "Spline",
      data: [[169024300000, 0, 0, 0]],
      settings: {
        color: "#33df90",
        lineWidth: 2,
        ZOOM: 3,
      },
      grid: {},
    },
  };
  chart: Object = new DataCube(this.singlePointPriceGraphLiveData);
  overlays: Array<object> = [];
  assetsBuy: any = {};
  asset: any = {};
  config: Object = {
    DEFAULT_LEN: 200,
    TB_BORDER: 5,
    CANDLEW: 0.9,
    GRIDX: 200,
    VOLSCALE: 0.5,
  };
  assetsLiveData: any = [];
  active: "1" = "1";
  baseDivWidth: any = this.GraphCWidth();
  baseDivHeight: any = this.GraphCHeight();
  chartWidth: number = this.baseDivWidth;
  chartHeight: number = this.baseDivHeight;
  noofChart: number = 1;

  totalInvestments: number = 0;
  currentAssetData: any = {};
  currentProfit: any = 0;
  currentProfitPerc: any = 0;

  mounted(): void {
    this.baseDivWidth = this.GraphCWidth();
    this.baseDivHeight = this.GraphCHeight();
  }

  GraphCWidth() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 250;
      case "sm":
        return 280;
      case "md":
        return 500;
      case "lg":
        return 750;
    }
  }

  GraphCHeight() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 300;
      case "sm":
        return 350;
      case "md":
        return 450;
      case "lg":
        return 450;
    }
  }

  @Auth.Getter
  private userGetter!: any;

  @Assets.Action
  private getAssetById!: (data: any) => Promise<any>;

  @Investments.Action
  private totalInvestmentByAsset!: (data: any) => Promise<any>;

  @Assets.Action
  private getAssetsLivePrice!: (data: any) => Promise<any>;

  getAssetsLivePriceData(endPoint: any) {
    this.getAssetsLivePrice({
      access: this.userGetter.access,
      apiEndpoint: endPoint,
    }).then((response) => {
      if (response.data) {
        this.assetsLiveData = [];
        this.assetsLiveData = response.data.reverse();
        this.singlePointPriceGraphLiveData.chart.data = [];
        this.assetsLiveData.forEach((item) => {
          this.singlePointPriceGraphLiveData.chart.data.push([
            Date.parse(item.date),
            parseFloat(item.open),
            parseFloat(item.high),
            parseFloat(item.low),
            parseFloat(item.price),
            parseFloat(item.vol),
          ]);
        });
      }
    });
  }

  getSinglePointPriceGraph(item) {
    this.getAssetsLivePriceData(ASSETS_ENDPOINTS[item])
  }

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  created() {
    if (this.$route.params.asset_id != null) {
      this.getAssetById({
        asset_id: this.$route.params.asset_id,
        access: this.userGetter.access,
      }).then((response) => {
        if (response.data) {
          this.asset = response.data;
          this.getSinglePointPriceGraph(this.asset.name);
        }

        this.getAssetById({
          asset_id: this.$route.params.asset_id,
          access: this.userGetter.access,
        }).then((response) => {
          if (response.data) {
            this.assetsBuy = response.data;
          }
        });

        this.totalInvestmentByAsset({
          asset_id: this.$route.params.asset_id,
          access: this.userGetter.access,
        }).then((res) => {
          if (res.data && res.data.length > 0) {
            this.currentAssetData = res.data[0];
            this.currentAssetData.asset = this.asset;

            this.totalInvestments = res.data[0].total_investment;

            this.currentProfit =
              this.asset.price * res.data[0].purchased_quantity -
              this.totalInvestments;
            if (isNaN(this.currentProfit)) this.currentProfit = 0;

            this.currentProfitPerc =
              (100 * this.currentProfit) / this.totalInvestments;
            if (isNaN(this.currentProfitPerc)) this.currentProfitPerc = 0;

            this.currentProfit = Number(this.currentProfit.toFixed(2));
            this.currentProfitPerc = Number(this.currentProfitPerc.toFixed(2));

            if (this.currentProfit == 0) {
              this.currentProfit = "$0";
            } else if (this.currentProfit > 0) {
              this.currentProfit = "+$" + this.currentProfit;
            } else if (this.currentProfit < 0) {
              this.currentProfit = "-$" + this.currentProfit * -1;
            }

            if (this.currentProfitPerc == 0) {
              this.currentProfitPerc = "0";
            } else if (this.currentProfitPerc > 0) {
              this.currentProfitPerc = "+" + this.currentProfitPerc;
            }
          }
        });
      });
    }

    eventBus.$on("changeAssetData", (data) => {
      if (this.currentAssetData.asset.pk == data.asset.pk) {
        this.currentAssetData = data;
        this.totalInvestments = data.total_investment;

        this.currentProfit =
          data.asset.price * data.purchased_quantity - this.totalInvestments;
        if (isNaN(this.currentProfit)) this.currentProfit = 0;

        this.currentProfitPerc =
          (100 * this.currentProfit) / this.totalInvestments;
        if (isNaN(this.currentProfitPerc)) this.currentProfitPerc = 0;

        this.currentProfit = Number(this.currentProfit.toFixed(2));
        this.currentProfitPerc = Number(this.currentProfitPerc.toFixed(2));

        if (this.currentProfit == 0) {
          this.currentProfit = "$0";
        } else if (this.currentProfit > 0) {
          this.currentProfit = "+$" + this.currentProfit;
        } else if (this.currentProfit < 0) {
          this.currentProfit = "-$" + this.currentProfit * -1;
        }

        if (this.currentProfitPerc == 0) {
          this.currentProfitPerc = "0";
        } else if (this.currentProfitPerc > 0) {
          this.currentProfitPerc = "+" + this.currentProfitPerc;
        }
      }
    });
  }
}
