





















































import { Vue, Component, Prop } from "vue-property-decorator";
import Pagination from "../../TradePortal/CoinDetail/pages/pagination/Pagination.vue";
import moment from "moment";

@Component({
  components: {
    Pagination,
  },
})
export default class UserTransactions extends Vue {
  page: number = 0;
  @Prop() pageCount!: any;
  @Prop() tokenTransferTransactionData!: any;

  headers: any = [
    {
      text: "TRANX NO",
      align: "start",
      sortable: false,
      value: "transaction_id",
    },
    { text: "TOKENS", value: "quantity" },
    { text: "AMOUNT", value: "amount" },
    { text: "FROM", value: "from" },
    { text: "BIDDING", value: "bidding" },
    { text: "STATUS", value: "status" },
  ];
  // items = [
  //   {
  //     tranx_no: [{ no: "TNX1002" }, { date: "2018-08-24 10:45PM" }],
  //     tokens: [{ price: "18,750" }, { currency: "TWZ" }],
  //     amount: [{ price: "50.00" }, { currency: "ETH" }],
  //     usd_amount: [{ price: "50.00" }, { currency: "USD" }],
  //     from: [{ code: "1F1T....4XQX" }, { date: "08 Jul, 18 10:20PM" }],
  //     type: "Purchase",
  //     view: "pay",
  //   },
  //   {
  //     tranx_no: [{ no: "TNX1002" }, { date: "2018-08-24 10:45PM" }],
  //     tokens: [{ price: "18,750" }, { currency: "TWZ" }],
  //     amount: [{ price: "50.00" }, { currency: "ETH" }],
  //     usd_amount: [{ price: "50.00" }, { currency: "USD" }],
  //     from: [{ code: "1F1T....4XQX" }, { date: "08 Jul, 18 10:20PM" }],
  //     type: "Purchase",
  //     view: "pay",
  //   },
  //   {
  //     tranx_no: [{ no: "TNX1002" }, { date: "2018-08-24 10:45PM" }],
  //     tokens: [{ price: "18,750" }, { currency: "TWZ" }],
  //     amount: [{ price: "50.00" }, { currency: "ETH" }],
  //     usd_amount: [{ price: "50.00" }, { currency: "USD" }],
  //     from: [{ code: "1F1T....4XQX" }, { date: "08 Jul, 18 10:20PM" }],
  //     type: "Purchase",
  //     view: "pay",
  //   },
  //   {
  //     tranx_no: [{ no: "TNX1002" }, { date: "2018-08-24 10:45PM" }],
  //     tokens: [{ price: "18,750" }, { currency: "TWZ" }],
  //     amount: [{ price: "50.00" }, { currency: "ETH" }],
  //     usd_amount: [{ price: "50.00" }, { currency: "USD" }],
  //     from: [{ code: "Bounty Bonus" }, { date: "Campaign Name" }],
  //     type: "Bonus",
  //     view: "pay",
  //   },
  // ];

  formatDate(val: string) {
    return moment(val).format("MMMM DD, YYYY h:mm a");
  }
}
