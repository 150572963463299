

























































import { Vue, Prop, Component } from "vue-property-decorator";
import Icon from "../../../globalUsage/Icon.vue";
@Component({
  components: {
    Icon,
  },
})
export default class ListingIsSuccessful extends Vue {
  @Prop() closeModal!: void;
  @Prop() assetData!: any;
  @Prop() postType!: string;
  @Prop() count!: number;

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }
}
