import { render, staticRenderFns } from "./SellCoinsDefault.vue?vue&type=template&id=7f32bd65&scoped=true&"
import script from "./SellCoinsDefault.vue?vue&type=script&lang=ts&"
export * from "./SellCoinsDefault.vue?vue&type=script&lang=ts&"
import style0 from "./SellCoinsDefault.vue?vue&type=style&index=0&id=7f32bd65&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f32bd65",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
