



































































































import { Vue, Component, Prop } from "vue-property-decorator";
import BuyNow from "../../buyNow/BuyNow.vue";
import SellNow from "../../SellNow/SellNow.vue";
import SeeDetail from "../seedetail/SeeDetails.vue";

import Icon from "../../../../../globalUsage/Icon.vue";

@Component({
  components: { Icon, BuyNow, SellNow, SeeDetail },
})
export default class All extends Vue {
  disableSort: boolean = false;
  text: object = {
    display: "flex",
  };
  headers: any = [
    {
      text: "ID",
      align: "start",
      sortable: false,
      value: "pk",
    },
    { text: "Amount", value: "traded_coins",   align: "center"},
    { text: "Trade with", align: "center", value: "accepted_coins" },
    { text: "Possible profit/lose", value: "-" ,align: "center"},
    { text: "Prices", value: "total_price",align: "center" },
    { text: "Remaining availability", value: "remaining_coins",align: "center" },
    { text: "", value: "post_type",align: "center" },
    { text: "", value: "SeeMoreButton" ,align: "center"},
  ];
  @Prop() postData!: any;
  @Prop() page!: number;
  @Prop() userBalance!: any;

  @Prop() getPostListingData!: (page: number) => Promise<any>;

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }
}
