import axios from "axios";
import { API_ENDPOINTS } from "./ApiEndpoints";


const API_URL = "https://replica.highpolar.in/v1/trading";

class TradingService {
    getTrading(
        page: number,
        page_size: number,
        access: string
    ) {
        return axios.get(API_ENDPOINTS.Trading.trading, {
            headers: {
                Authorization: "Bearer " + access
            },
            params: {
                page,
                page_size,
            }
        });
    }
}

export default new TradingService();