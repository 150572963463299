
























import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");

@Component({
  components: {},
})
export default class PaymentFailed extends Vue {
  @Auth.Getter
  private userGetter!: any;

  home() {
    this.$router.push("/landing-page");
  }

  created() {}
}
