const BASE_URL = "https://replica.highpolar.in/v1";
// const BASE_URL = "http://127.0.0.1:8000/v1";
// const BASE_URL = "http://192.168.29.90:8000/v1";

export const API_ENDPOINTS = {
  Assets: {
    asset: BASE_URL + "/assets",
    copperLivePrice:  BASE_URL + "/graph-data/copper/",
    goldLivePrice:  BASE_URL + "/graph-data/gold/",
    naturalGasLivePrice:  BASE_URL + "/graph-data/natural-gas/",
    oilLivePrice:  BASE_URL + "/graph-data/oil/",
    platinumLivePrice:  BASE_URL + "/graph-data/platinum/",
    silverLivePrice:  BASE_URL + "/graph-data/silver/",
    assetLivePrice: BASE_URL + "/graph-data/asset-live-price/"
  },
  Auth: {
    login: BASE_URL + "/auth/login",
    signup: BASE_URL + "/auth/signup",
  },
  InvestmentOrders: {
    investmentOrders: BASE_URL + "/investment_orders",
    investmentOrdersPast: BASE_URL + "/investment_orders/total_investment_past",
    investmentOrdersPastId: BASE_URL + "/investment_orders/past-details",
  },
  InvestmentsService: {
    investments: BASE_URL + "/investments",
    Buy: BASE_URL + "/investments/buy",
    Sell: BASE_URL + "/investments/sell",
    total_investment: BASE_URL + "/investments/total_investment",
  },
  Notifications: {
    notifications: BASE_URL + "/notification",
  },
  PostListing: {
    postListing: BASE_URL + "/post-listing",
    Buy: BASE_URL + "/post-listing/buy",
    Sell: BASE_URL + "/post-listing/sell",
    mylistings: BASE_URL + "/post-listing/my_listings",
    mylistingsPast: BASE_URL + "/post-listing/my_listings_past",
  },
  Trading: {
    trading: BASE_URL + "/trading",
    Buy: BASE_URL + "/trading/buy",
    Sell: BASE_URL + "/trading/sell",
    total_investment: BASE_URL + "/trading/total_investment",
  },
  User: {
    user: BASE_URL + "/users",
    forgot_password_email_link: BASE_URL + "/users/forgot_password_email_link",
    change_password: BASE_URL + "/users/change_password",
  },
  Wallet: {
    wallet: BASE_URL + "/wallet",
  },
  Profile: {
    profile: BASE_URL + "/profile",
    update_profile: BASE_URL + "/update_profile",
  },
  TradingProposal: {
    TradingProposal: BASE_URL + "/trading-proposal",
  },
  TokenTransferTransaction:{
    tokenTransferTransaction : BASE_URL + "/paypal_token_transaction",
  },
  InitialCoinOffering:{
    InitialCoinOffering: BASE_URL + "/initial-coin-offering",
    BiddingAssetQuantityCount : BASE_URL + "/bidding-asset-quantity-count/",
    createBidding: BASE_URL + "/bidding"
  },
  PayWithPaypal:{
    PayWithPaypal :BASE_URL + "/paypal-payment/"
  },
  Balance:{
    getBalance: BASE_URL + "/user_blockchain_wallet_balance/"
  }
};

export const POST_TYPE = {
  BUY : "BUY",
  SELL : "SELL"
};

export const  ASSETS_ENDPOINTS = {
  "GOLD 18" : API_ENDPOINTS.Assets.goldLivePrice,
  "GOLD" : API_ENDPOINTS.Assets.goldLivePrice,
  "GOLD 21" : API_ENDPOINTS.Assets.goldLivePrice,
  "USD" : API_ENDPOINTS.Assets.goldLivePrice,
  "EURO" : API_ENDPOINTS.Assets.goldLivePrice,
  "SILVER 24" : API_ENDPOINTS.Assets.silverLivePrice,
  "COPPER" : API_ENDPOINTS.Assets.copperLivePrice,
  "PLATINUM": API_ENDPOINTS.Assets.platinumLivePrice,
  "OIL": API_ENDPOINTS.Assets.oilLivePrice,
  "GAS" : API_ENDPOINTS.Assets.naturalGasLivePrice
}