





















































































































































































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import moment from "moment";
import Icon from "../components/globalUsage/Icon.vue";
import BuyBtn from "../components/dashboard/buyCoins/BuyBtn.vue";
import TradeBtn from "../components/dashboard/trade/TradeBtn.vue";
import MarketPlace from "../components/dashboard/marketPlace/MarketPlace.vue";
import SetAutoTrade from "../components/dashboard/autoTrade/SetAutoTrade.vue";
import DataPoint from "../components/dashboard/dataPoint/DataPoint.vue";
import ChartType from "../components/dashboard/chartType/ChartType.vue";
import Data from "../../data.json";
// import Data1 from "../graphData/singlePointPriceGraph.json";
// import Data2 from "../graphData/twoPointPriceGraph.json";
// import Data3 from "../graphData/threePointPriceGraph.json";
// import Data4 from "../graphData/fourPointPriceGraph.json";
import TradingVue from "trading-vue-js";
import { API_ENDPOINTS } from "../services/ApiEndpoints";
import { DataCube } from "trading-vue-js";
import GoToDate from "../components/dashboard/date/GoToDate.vue";
import { eventBus } from "@/main";
import date from "../components/dashboard/date/DateSelect.vue";
const Auth = namespace("Auth");
const Assets = namespace("Assets");
const Investments = namespace("Investments");

@Component({
  components: {
    Icon,
    BuyBtn,
    TradeBtn,
    MarketPlace,
    SetAutoTrade,
    DataPoint,
    ChartType,
    TradingVue,
    DataCube,
    GoToDate,
    date,
  },
})
export default class TradingPortal extends Vue {
  page: 1 = 1;
  assetsLiveData: any = [];
  // chart1: Object = new DataCube(Data1);
  // chart2: Object = new DataCube(Data2);
  // chart3: Object = new DataCube(Data3);
  // chart4: Object = new DataCube(Data4);

  chart: Object = new DataCube(Data);
  graphLiveData: Array<any> = [[0, 0, 0, 0, 0, 0]];
  singlePointPriceGraphLiveData = {
    chart: {
      type: "Spline",
      data: [[169024300000, 0, 0, 0]],
      settings: {
        color: "#33df90",
        lineWidth: 2,
        ZOOM: 3,
      },
      grid: {},
    },
  };

  twoPointPriceGraphLiveData = {
    chart: {
      type: "Spline",
      data: [[1691577080000, 0, 0, 0, 0, 0]],
      settings: {
        color: "#df5b33",
        lineWidth: 2,
        ZOOM: 3,
      },
      grid: {},
    },
  };

  threePointPriceGraphLiveData = {
    chart: {
      type: "Spline",
      data: [[1691577080000, 0, 0, 0, 0, 0]],
      settings: {
        color: "#2b64f8",
        lineWidth: 2,
        ZOOM: 3,
      },
      grid: {},
    },
  };

  fourPointPriceGraphLiveData = {
    chart: {
      type: "Spline",
      data: [[1691577080000, 0, 0, 0, 0, 0]],
      settings: {
        color: "#ff9800",
        lineWidth: 2,
        ZOOM: 3,
      },
      grid: {},
    },
  };
  chart1: Object = new DataCube(this.singlePointPriceGraphLiveData);
  chart2: Object = new DataCube(this.twoPointPriceGraphLiveData);
  chart3: Object = new DataCube(this.threePointPriceGraphLiveData);
  chart4: Object = new DataCube(this.fourPointPriceGraphLiveData);
  overlays: Array<object> = [];
  config = {
    DEFAULT_LEN: 150,
    TB_BORDER: 200,
    CANDLEW: 5,
    GRIDX: 100,
    VOLSCALE: 5,
  };
  active: "1" = "1";
  noofChart: number = 1;
  baseDivWidth: any = this.GraphWidth();
  baseDivHeight: any = this.GraphHeight();
  chart1Width: number = this.baseDivWidth;
  chart1Height: number = this.baseDivHeight;
  chart2Width: number = this.baseDivWidth;
  chart2Height: number = this.baseDivHeight / 2;
  chart3Width: number = this.baseDivWidth / 2;
  chart3Height: number = this.baseDivHeight / 2;
  chart4Width: number = this.baseDivWidth / 2;
  chart4Height: number = this.baseDivHeight / 2;
  availableAssets: boolean = false;
  showDataPoint: boolean = false;
  showDataSelect: boolean = false;
  handleChart: boolean = false;
  assetSelected: boolean = false;
  noData: boolean = false;
  value: number = 1;
  selectAssetsValue: any = ["" ,"One", "Two", "Three", "Four"];
  selectedMyAssets: string[] = [];

  currentInvestmentsPage: number = 1;
  investmentsCount: number = 0;
  investmentsData: Array<object> = [];

  showAssetsBlock: boolean = false;
  currentAssetsPage: number = 1;
  assetsCount: number = 0;
  assetsData: Array<object> = [];
  assetsBuy: any = {};
  assetsLivePriceData: any = [];
  silverAssetsLiveData: any = [];
  oilAssetsLiveData: any = [];
  copperAssetsLiveData: any = [];
  platinumAssetsLiveData: any = [];
  naturalGasAssetsLiveData: any = [];

  totalInvestments: number = 0;
  currentAssetData: any = null;
  currentPrice: any = 0;
  currentProfit: any = 0;
  currentProfitPerc: any = 0;
  date: string = "";
  date1: string = "";
  date2: string = "";
  days: string = "";
  livedata: any = {
    asset_name: "",
    start_time: "",
    end_time: "",
  };
  assetsName = {
    "GOLD": "gold",
    "GOLD 18": "gold",
    "GOLD 21": "gold",
    "SILVER 24": "silver",
    "COPPER": "copper",
    "GAS": "natural-gas",
    "OIL": "oil",
    "PLATINUM": "platinum",
  };
  // selectDays: string = "";
  dateRange: boolean = false;
  selectDate: boolean = true;
  setRangeClicked(): any {
    this.date = "";
    this.date2 = "";
    this.date2 = "";
    this.dateRange = !this.dateRange;
    this.selectDate = !this.selectDate;
  }
  public handleDate(date: any): any {
    this.date = moment(date).format("LL");
  }
  public handleDate1(date1: any): any {
    this.date1 = moment(date1).format("LL");
  }
  public handleDate2(date2: any): any {
    this.date2 = moment(date2).format("LL");
  }
  currentAssetValue: string = "";

  loading: boolean = false;

  today = new Date();

  year = this.today.getFullYear();
  month = (this.today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so we add 1
  day = this.today.getDate().toString().padStart(2, "0");

  hideLoading = setTimeout(() => {
    this.loading = false;
  }, 500);

  assetLivePrice() {
    this.singlePointPriceGraphLiveData.chart.data = [];
    this.getAssetLiveDayPrice({
      asset_name:
        this.currentAssetValue || this.selectedMyAssets[0]
          ? this.assetsName[this.currentAssetValue] || this.selectedMyAssets[0]
          : "gold",
      start_time: `${this.year}-${this.month}-${this.day}T01:00:21.900Z`,
      end_time: `${this.year}-${this.month}-${this.day}T23:59:21.900Z`,
    }).then((response) => {
      if (response.data) {
        this.singlePointPriceGraphLiveData.chart.data = [];
        [...response.data].forEach((item) => {
          this.singlePointPriceGraphLiveData.chart.data.push([
            // new Date(item.timestamp).getTime(),
            Date.parse(item.timestamp),
            parseFloat(item.price),
            parseFloat(item.price),
            parseFloat(item.price),
            parseFloat(item.price),
            parseFloat(item.price),
          ]);
        });
      }
    });
  }

  selectDays(day: string): void {
    this.loading = true;

    if (this.days == day) {
      this.days = "";
    } else {
      this.days = day;
    }

    if (this.days != "1 day") {
      this.getSinglePointPriceGraph();
      this.getTwoPointPriceGraph();
      this.getThreePointPriceGraph();
      this.getFourPointPriceGraph();
    }

    switch (this.days) {
      case "1 day":
        // this.config = {
        //   DEFAULT_LEN: 1,
        //   TB_BORDER: 50,
        //   CANDLEW: 0.1,
        //   GRIDX: 20,
        //   VOLSCALE: 5,
        // };
        this.assetLivePrice();
        this.hideLoading;
        break;
      case "7 day":
        this.config = {
          DEFAULT_LEN: 7,
          TB_BORDER: 1,
          CANDLEW: 1,
          GRIDX: 1,
          VOLSCALE: 5,
        };
        this.hideLoading;
        break;
      case "1 month":
        this.config = {
          DEFAULT_LEN: 22,
          TB_BORDER: 150,
          CANDLEW: 0.1,
          GRIDX: 30,
          VOLSCALE: 10,
        };
        this.hideLoading;
        break;
      case "3 month":
        this.config = {
          DEFAULT_LEN: 65,
          TB_BORDER: 170,
          CANDLEW: 3,
          GRIDX: 80,
          VOLSCALE: 10,
        };
        this.hideLoading;
        break;
      case "1 year":
        this.config = {
          DEFAULT_LEN: 300,
          TB_BORDER: 300,
          CANDLEW: 10,
          GRIDX: 200,
          VOLSCALE: 50,
        };
        this.hideLoading;
        break;
      default:
        this.config = {
          DEFAULT_LEN: 150,
          TB_BORDER: 200,
          CANDLEW: 5,
          GRIDX: 100,
          VOLSCALE: 5,
        };
        this.hideLoading;
    }
  }

  mounted(): void {
    var drawingBuffer = document.getElementsByClassName("graph-map")[0];
    if (drawingBuffer) {
      this.baseDivWidth = Number(drawingBuffer.clientWidth);
      this.baseDivHeight = Number(drawingBuffer.clientHeight);
      this.chart1Width = this.baseDivWidth;
      this.chart1Height = this.baseDivHeight;
      this.baseDivWidth = this.GraphWidth();
      this.baseDivHeight = this.GraphHeight();
    }
    this.getAssetsDataLivePrice(
      API_ENDPOINTS.Assets.goldLivePrice,
      this.singlePointPriceGraphLiveData
    );
  }

  get canSelect(): boolean {
    return this.selectedMyAssets.length < this.value;
  }

  isSelected(asset: string): boolean {
    return this.selectedMyAssets.includes(asset);
  }

  GraphWidth() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 240;
      case "sm":
        return 400;
      case "md":
        return 500;
      case "lg":
        return 700;
    }
  }

  GraphHeight() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 250;
      case "sm":
        return 350;
      case "md":
        return 450;
      case "lg":
        return 500;
    }
  }

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  closeModal(): void {
    this.showDataPoint = !this.showDataPoint;
  }

  AppearModal(): void {
    this.showDataPoint = true;
  }

  closeChart(): void {
    this.handleChart = !this.handleChart;
  }

  AppearChart(): void {
    this.handleChart = true;
  }

  changeChartType(chartType: string): void {
    this.singlePointPriceGraphLiveData.chart.type = chartType;
    this.twoPointPriceGraphLiveData.chart.type = chartType;
    this.threePointPriceGraphLiveData.chart.type = chartType;
    this.fourPointPriceGraphLiveData.chart.type = chartType;
    if (chartType == "Candles") {
      Data.chart.type = "Candles";
      this.chart = new DataCube(Data);
    } else {
      Data.chart.type = "Spline";
      this.chart = Data;
    }
  }

  DateSelect(): void {
    this.showDataSelect = !this.showDataSelect;
    this.getSinglePointPriceGraph();
    this.loading = true;

    // Date format and date to find
    if (this.date) {
      let dates = moment(this.date).format().slice(0, 10);
      const dateToFind = Date.parse(dates);

      const index = this.singlePointPriceGraphLiveData.chart.data.findIndex(
        (item) => item.some((elem) => elem === dateToFind)
      );

      if (index !== -1) {
        this.config = {
          DEFAULT_LEN: 20,
          TB_BORDER: 0.1,
          CANDLEW: 0.1,
          GRIDX: 0.2,
          VOLSCALE: 5,
        };
        this.overlays = [
          {
            color: "red",
            lineStyle: "dotted",
            priceScale: 0,
            time: dates,
            scalePrice: 0,
          },
        ];
        const startIndex = Math.max(0, index - 30);
        const endIndex = Math.max(0, index + 10);

        const sevenPointsData =
          this.singlePointPriceGraphLiveData.chart.data.slice(
            startIndex,
            endIndex + 1
          );

        // Update the chart data to display the seven points
        this.singlePointPriceGraphLiveData.chart.data = sevenPointsData;
        setTimeout(() => {
          this.loading = false;
        }, 500);
      } else {
        this.singlePointPriceGraphLiveData.chart.data = [];
        this.noData = true;
      }
    }
  }

  closeDateSelect(): void {
    this.showDataSelect = !this.showDataSelect;
    location.reload();
    // this.mounted();
    this.dateRange = false;
    this.selectDate = true;
  }

  DataPointsToShow(numberofDataPoint: number): void {
    console.log("DataPointsToShow called" + numberofDataPoint);
    this.noofChart = numberofDataPoint;
    if (numberofDataPoint == 1) {
      this.selectedMyAssets = [];
      this.value = 1;
      this.closeModal();
      this.chart1Width = this.baseDivWidth;
      this.chart1Height = this.baseDivHeight;
    }
    if (numberofDataPoint == 2) {
      this.value = 2;
      this.chart1Width = this.baseDivWidth;
      this.chart1Height = this.baseDivHeight / 2;
      this.chart2Width = this.baseDivWidth;
      this.closeModal();
    }
    if (numberofDataPoint == 3) {
      this.value = 3;
      this.chart1Width = this.baseDivWidth;
      this.chart1Height = this.baseDivHeight / 2;
      this.chart2Width = this.baseDivWidth / 2;
      this.closeModal();
    }
    if (numberofDataPoint == 4) {
      this.value = 4;
      this.chart1Width = this.baseDivWidth / 2;
      this.chart1Height = this.baseDivHeight / 2;
      this.chart2Width = this.baseDivWidth / 2;
      this.chart3Width = this.baseDivWidth / 2;
      this.chart4Width = this.baseDivWidth / 2;
      this.chart2Height = this.baseDivHeight / 2;
      this.chart3Height = this.baseDivHeight / 2;
      this.chart4Height = this.baseDivHeight / 2;
      this.closeModal();
    }
  }

  getProfitLoss(item) {
    let profit =
      item.asset.price * item.purchased_quantity - item.total_investment;
    if (isNaN(profit)) profit = 0;

    let perc = (100 * profit) / item.total_investment;
    if (isNaN(perc)) perc = 0;
    return Number(perc.toFixed(2));
  }

  @Auth.Getter
  private userGetter!: any;

  @Assets.Action
  private getAssets!: (data: any) => Promise<any>;

  @Assets.Action
  private getAssetById!: (data: any) => Promise<any>;

  @Assets.Action
  private getAssetsLivePrice!: (data: any) => Promise<any>;

  @Assets.Action
  private getAssetLiveDayPrice!: (data: any) => Promise<any>;

  @Investments.Action
  private getInvestments!: (data: any) => Promise<any>;

  @Investments.Action
  private totalInvestmentByAsset!: (data: any) => Promise<any>;

  getAssetsDataLivePrice(endPoint: any, dataPoint?: any) {
    this.loading = true;
    let hideLoading = setTimeout(() => {
      this.loading = false;
    }, 500);
    this.getAssetsLivePrice({
      access: this.userGetter.access,
      apiEndpoint: endPoint,
    }).then((response) => {
      if (response.data) {
        dataPoint.chart.data = [];
        this.assetsLivePriceData = [];
        this.assetsLivePriceData = response.data.reverse();
        if (this.date1 && this.date2) {
          let startTimeFormat = moment(this.date1).format().slice(0, 10);
          let endTimeFormat = moment(this.date2).format().slice(0, 10);
          this.assetsLivePriceData = this.assetsLivePriceData.filter(
            (element) =>
              element.date >= startTimeFormat && element.date <= endTimeFormat
          );
        }
        this.assetsLivePriceData.forEach((item) => {
          dataPoint.chart.data.push([
            Date.parse(item.date),
            parseFloat(item.open),
            parseFloat(item.high),
            parseFloat(item.low),
            parseFloat(item.price),
            parseFloat(item.vol),
          ]);
        });
        hideLoading;
      }
    });
  }

  getSinglePointPriceGraph() {
    switch (this.currentAssetValue || this.selectedMyAssets[0]) {
      case "GOLD 18":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.goldLivePrice,
          this.singlePointPriceGraphLiveData
        );
        break;
      case "SILVER 24":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.silverLivePrice,
          this.singlePointPriceGraphLiveData
        );
        break;
      case "COPPER":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.copperLivePrice,
          this.singlePointPriceGraphLiveData
        );
        break;
      case "PLATINUM":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.platinumLivePrice,
          this.singlePointPriceGraphLiveData
        );
        break;
      case "OIL":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.oilLivePrice,
          this.singlePointPriceGraphLiveData
        );
        break;
      case "GAS":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.naturalGasLivePrice,
          this.singlePointPriceGraphLiveData
        );
        break;
      default:
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.goldLivePrice,
          this.singlePointPriceGraphLiveData
        );
    }
  }

  getTwoPointPriceGraph() {
    switch (this.selectedMyAssets[1]) {
      case "GOLD 18":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.goldLivePrice,
          this.twoPointPriceGraphLiveData
        );
        break;
      case "SILVER 24":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.silverLivePrice,
          this.twoPointPriceGraphLiveData
        );
        break;
      case "COPPER":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.copperLivePrice,
          this.twoPointPriceGraphLiveData
        );
        break;
      case "PLATINUM":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.platinumLivePrice,
          this.twoPointPriceGraphLiveData
        );
        break;
      case "OIL":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.oilLivePrice,
          this.twoPointPriceGraphLiveData
        );
        break;
      case "GAS":
        this.getAssetsDataLivePrice(API_ENDPOINTS.Assets.naturalGasLivePrice),
          this.twoPointPriceGraphLiveData;
        break;
      default:
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.goldLivePrice,
          this.twoPointPriceGraphLiveData
        );
    }
  }

  getThreePointPriceGraph() {
    switch (this.selectedMyAssets[2]) {
      case "GOLD 18":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.goldLivePrice,
          this.threePointPriceGraphLiveData
        );
        break;
      case "SILVER 24":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.silverLivePrice,
          this.threePointPriceGraphLiveData
        );
        break;
      case "COPPER":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.copperLivePrice,
          this.threePointPriceGraphLiveData
        );
        break;
      case "PLATINUM":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.platinumLivePrice,
          this.threePointPriceGraphLiveData
        );
        break;
      case "OIL":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.oilLivePrice,
          this.threePointPriceGraphLiveData
        );
        break;
      case "GAS":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.naturalGasLivePrice,
          this.threePointPriceGraphLiveData
        );
        break;
      default:
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.goldLivePrice,
          this.threePointPriceGraphLiveData
        );
    }
  }

  getFourPointPriceGraph() {
    switch (this.selectedMyAssets[3]) {
      case "GOLD 18":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.goldLivePrice,
          this.fourPointPriceGraphLiveData
        );
        break;
      case "SILVER 24":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.silverLivePrice,
          this.fourPointPriceGraphLiveData
        );
        break;
      case "COPPER":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.copperLivePrice,
          this.fourPointPriceGraphLiveData
        );
        break;
      case "PLATINUM":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.platinumLivePrice,
          this.fourPointPriceGraphLiveData
        );
        break;
      case "OIL":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.oilLivePrice,
          this.fourPointPriceGraphLiveData
        );
        break;
      case "GAS":
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.naturalGasLivePrice,
          this.fourPointPriceGraphLiveData
        );
        break;
      default:
        this.getAssetsDataLivePrice(
          API_ENDPOINTS.Assets.goldLivePrice,
          this.fourPointPriceGraphLiveData
        );
    }
  }

  setMenu(showAssetsBlock) {
    if (showAssetsBlock) {
      this.showAssetsBlock = true;
    } else {
      this.showAssetsBlock = false;
    }
    this.availableAssets = false;
  }

  selectAssetsData(asset) {
    this.assetSelected = true;
    this.currentAssetValue = "";
    function findassets(fill: any) {
      return fill == asset;
    }
    if (this.isSelected(asset) && this.selectedMyAssets.length > 1) {
      this.selectedMyAssets.splice(this.selectedMyAssets.indexOf(asset), 1);
    } else if (!this.isSelected(asset) && this.canSelect) {
      // this.selectedMyAssets.push(asset.name);
      // this.changeData(asset);
      let valueAssets = this.selectedMyAssets.find(findassets);
      let valueindex = this.selectedMyAssets.indexOf(asset);
      if (valueAssets == asset) {
        // this.assetsName.pop()
        this.selectedMyAssets.splice(valueindex, 1);
      } else {
        this.selectedMyAssets.push(asset);
      }
    }
    if (this.days == "1 day") {
      this.assetLivePrice();
      this.hideLoading;
    } else {
      this.getSinglePointPriceGraph();
      this.getTwoPointPriceGraph();
      this.getThreePointPriceGraph();
      this.getFourPointPriceGraph();
    }
  }

  changeData(asset) {
    this.currentAssetValue = asset.name;
    if (this.days == "1 day") {
      this.assetLivePrice();
      this.hideLoading;
    } else {
      this.getSinglePointPriceGraph();
    }
    this.totalInvestmentByAsset({
      asset_id: asset.pk,
      access: this.userGetter.access,
    }).then((response) => {
      if (response.data && response.data.length > 0) {
        response.data[0].asset = asset;
        this.currentAssetData = response.data[0];
        this.currentPrice = asset.price;
        this.totalInvestments = response.data[0].total_investment;

        this.currentProfit =
          asset.price * response.data[0].purchased_quantity -
          this.totalInvestments;
        if (isNaN(this.currentProfit)) this.currentProfit = 0;

        this.currentProfitPerc =
          (100 * this.currentProfit) / this.totalInvestments;
        if (isNaN(this.currentProfitPerc)) this.currentProfitPerc = 0;

        this.currentProfit = Number(this.currentProfit.toFixed(2));
        this.currentProfitPerc = Number(this.currentProfitPerc.toFixed(2));

        if (this.currentProfit == 0) {
          this.currentProfit = "$0";
        } else if (this.currentProfit > 0) {
          this.currentProfit = "+$" + this.currentProfit;
        } else if (this.currentProfit < 0) {
          this.currentProfit = "-$" + this.currentProfit * -1;
        }

        if (this.currentProfitPerc == 0) {
          this.currentProfitPerc = "0";
        } else if (this.currentProfitPerc > 0) {
          this.currentProfitPerc = "+" + this.currentProfitPerc;
        }
      }
    });

    this.getAssetById({
      asset_id: asset.pk,
      access: this.userGetter.access,
    }).then((response) => {
      if (response.data) {
        this.assetsBuy = response.data;
      }
    });
  }

  getInvestmentsData(page: number) {
    this.getInvestments({
      access: this.userGetter.access,
      page: page,
      page_size: 4,
    }).then((response) => {
      this.currentInvestmentsPage = page;
      if (response.data.count) {
        this.investmentsCount = response.data.count;
      }
      if (response.data.results) {
        this.investmentsData = response.data.results;
        if (this.investmentsData.length > 0 && this.currentAssetData == null) {
          this.currentAssetData = this.investmentsData[0];
        }
      }
    });
  }

  getAssetsData(page: number) {
    this.getAssets({
      access: this.userGetter.access,
      page: page,
      page_size: 4,
    }).then((response) => {
      this.currentAssetsPage = page;
      if (response.data.count) {
        this.assetsCount = response.data.count;
      }
      if (response.data.results) {
        this.assetsData = response.data.results;
      }
    });
  }

  created() {
    // this.get(this.userGetter)
    //   .then((res) => this.getAssetsSuccess(res.data))
    //   .catch((e) => this.getAssetsFailure(e));
    this.getInvestmentsData(1);
    this.getAssetsData(1);
    this.getAssetsDataLivePrice(
      API_ENDPOINTS.Assets.goldLivePrice,
      this.singlePointPriceGraphLiveData
    );
    // this.getSinglePointPriceGraph();

    eventBus.$on("changeAssetData", (data) => {
      this.investmentsData.forEach((element) => {
        if (element["asset"]["pk"] == data.asset.pk) {
          this.$set(element, "purchased_quantity", data.purchased_quantity);
        }
      });

      if (this.currentAssetData.asset.pk == data.asset.pk) {
        this.currentAssetData = data;
        this.currentPrice = data.asset.price;
        this.totalInvestments = data.total_investment;

        this.currentProfit =
          data.asset.price * data.purchased_quantity - this.totalInvestments;
        if (isNaN(this.currentProfit)) this.currentProfit = 0;

        this.currentProfitPerc =
          (100 * this.currentProfit) / this.totalInvestments;
        if (isNaN(this.currentProfitPerc)) this.currentProfitPerc = 0;

        this.currentProfit = Number(this.currentProfit.toFixed(2));
        this.currentProfitPerc = Number(this.currentProfitPerc.toFixed(2));

        if (this.currentProfit == 0) {
          this.currentProfit = "$0";
        } else if (this.currentProfit > 0) {
          this.currentProfit = "+$" + this.currentProfit;
        } else if (this.currentProfit < 0) {
          this.currentProfit = "-$" + this.currentProfit * -1;
        }

        if (this.currentProfitPerc == 0) {
          this.currentProfitPerc = "0";
        } else if (this.currentProfitPerc > 0) {
          this.currentProfitPerc = "+" + this.currentProfitPerc;
        }
      }
    });
  }
}
