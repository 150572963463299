












































































































import {
  Component,
  Prop,
  Mixins,
  Provide,
  Watch
} from "vue-property-decorator";
import Icon from "./Icon.vue";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const Users = namespace("Users");

import FormValidationRules from "@/mixins/FormValidationRules";

@Component({
  components: { Icon }
})
export default class AccountDetails extends Mixins(FormValidationRules) {
  dialog: boolean = false;
  IsErrorinInput: boolean = false;
  @Prop() HistoryTitle!: string;
  @Prop() activityHistory!: string;
  showResetPassDialog: boolean = false;
  showPassword: boolean = false;
  private user: any = {
    email: "",
    password: "",
    name: "",
    phone_number: "",
    profile_photo: null
  };
  showNewPassword: boolean = false;

  toastMsg: string = "";
  fileLoadErrorDialog: boolean = false;
  profile_img: any = null;
  profile_photo: any = null;

  oldPassword: string = "";
  newPassword: string = "";
  changePasswordError: string = "";
  changePasswordDialoge: boolean = false;

  name: string = "";
  email: string = "";
  emailError: string = "";
  phone_number: string = "";

  @Provide() rules: any = [
    (value: any) =>
      !value || value.size < 2000000 || "Avatar size should be less than 2 MB!"
  ];

  @Auth.Getter
  private userGetter!: any;

  @Users.Getter
  private userProfileGetter!: any;

  @Users.Action
  private updateProfile!: (data: any) => Promise<any>;

  @Users.Mutation
  private updateProfileSuccess!: (profile: any) => any;

  @Users.Mutation
  private updateProfileFailure!: (error: any) => void;

  @Watch("dialog")
  onChange() {
    this.$root.$emit("refreshUserData");
  }

  @Users.Action
  private getProfile!: (data: any) => Promise<any>;

    @Users.Mutation
  private getProfileFailure!: (error: any) => void;

  @Users.Mutation
  private getProfileSuccess!: (userProfile: any) => any;

  @Users.Action
  private changePassword!: (data: any) => Promise<any>;

  @Users.Mutation
  private changePasswordSuccess!: (response: any) => void;

  @Users.Mutation
  private changePasswordFailure!: (error: any) => void;

  mounted() {
    // console.log("88888888888888888888888888 - ",this.userProfileGetter)
    this.user = {
      ...this.userProfileGetter
    };

    this.changeProfile()
  
  }


  changeProfile() {

    let enteredDataStatusOk = (this.$refs.form as Vue & {
      validate: () => boolean;
    }).validate();
    const self = this;
    if (enteredDataStatusOk) {
      self
        .updateProfile({
          ...self.userGetter,
          field: {
            name: this.user.name,
            email: this.user.email,
            phone_number: this.user.phone_number,
            profile_photo: this.profile_img,
          }
        })
        .then(data => {
          self.updateProfileSuccess(data);
          this.$toasted.show("Data updated successfully");
          this.dialog = false;
          self.userGetter &&
            self
              .getProfile(self.userGetter)
              .then((resp) => {
                self.getProfileSuccess(resp.data);
              })
              .catch((e) => self.getProfileFailure(e));
          this.$store.state.loader = false;
        })
        .catch(error => {
          self.updateProfileFailure(error);
          this.$toasted.show(error.email[0]);
        });
    }
  }

  // validateLogIn() {
  //   let enteredDataStatusOk = (this.$refs.form as Vue & {
  //     validate: () => boolean;
  //   }).validate();
  //   if (enteredDataStatusOk) {
  //     this.login(this.user).then(
  //       data => {
  //         console.log(data);
  //       },
  //       error => {
  //         console.log(error);
  //       }
  //     );
  //   }
  // }

  onChangeProfileImage(event: any) {
    this.profile_img = event.target.files[0];

    if (!event) {
      this.fileLoadErrorDialog = true;
    }
    let reader = new FileReader();
    // Use the javascript reader object to load the contents
    reader.readAsDataURL(event.target.files[0]);

    reader.onerror = () => {
      this.fileLoadErrorDialog = true;
    };

    reader.onload = () => {
      this.user.profile_photo = reader.result;
    };
  }

  removerImage() {
    this.profile_img = null;
    this.user.profile_photo = null;
  }

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  updateDetails() {
    this.emailError = '';
    let check = (this.$refs.form as Vue & { validate: () => boolean; }).validate();
    if (check) {
      this.$store.state.loader = true;
      this.updateProfile({
        access: this.userGetter.access,
        user_id: this.userGetter.user_id,
        field: {
          name: this.user.name,
          email: this.user.email,
          phone_number: this.user.phone_number,
          profile_photo: this.profile_img,
        }
      }).then(
        () => {
          this.$toasted.show("Changes are been successfully applied");
          this.dialog = false;
          this.$store.state.loader = false;
        },
        (error) => {
          this.emailError = error.email[0];
          this.$store.state.loader = false;
        }
      );
    }
   
  }

  changeUserPassword() {
    let check = (this.$refs.form as Vue & { validate: () => boolean; }).validate();
    if (check) {
      this.$store.state.loader = true;
      this.changePassword({
        access: this.userGetter.access,
        old_password: this.oldPassword,
        new_password: this.newPassword
      }).then(
        response => {
          if (response) {
            this.$toasted.show("Password changed successfully");
            this.changePasswordDialoge = false;
            this.oldPassword = "";
            this.newPassword = "";
          }
          this.$store.state.loader = false;
        },
        (error) => {
          this.changePasswordError = error.message;
          this.$store.state.loader = false;
        }
      );
    }
  }
}
