


































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import moment from "moment";

import Icon from "@/components/globalUsage/Icon.vue";
import CoinDetail from "../../coinsDetails/CoinDetail.vue";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const InvestmentOrders = namespace("InvestmentOrders");

@Component({
  components: { CoinDetail, Icon },
})
export default class PastProfileTab extends Vue {
  dialog: false = false;
  totalRecords: number = 0;
  page: number = 1;
  data: any = [];
  url: string = "https://replica.highpolar.in";

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  @Auth.Getter
  private userGetter!: any;

  @InvestmentOrders.Action
  private getInvestmentOrdersPast!: (data: any) => Promise<any>;

  getDate(date: any) {
    return moment(date).format("LL hh:mm");
  }

  getInvestmentsData(page: number) {
    this.getInvestmentOrdersPast({
      access: this.userGetter.access,
      page: page,
      page_size: 10,
    }).then((response) => {
      this.data = this.data.concat(response.data);
    });
  }

  created() {
    this.getInvestmentsData(1);
  }
}
