



























import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");

@Component({
  components: {},
})
export default class PaymentSuccess extends Vue {
  loader: boolean = false;

  @Auth.Getter
  private userGetter!: any;

  next() {
    console.log("Next");
    this.$router.push("/dashboard");
  }
}
