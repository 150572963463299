





































import { Vue, Component, Prop } from "vue-property-decorator";
import SelectCoin from "../../selectCoin/SelectCoin.vue";
import AddAmount from "../addAmount.vue";
import AcceptCoinsOrTransaction from "../AcceptCoinsOrTransaction.vue";
import BTN from "../../globalUsage/Button.vue";
@Component({
  components: { SelectCoin, BTN, AddAmount, AcceptCoinsOrTransaction }
})
export default class FirstCard extends Vue {
  @Prop() goNext!: void;
  @Prop() closeModal!: void;
}
