





















































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { eventBus } from "../../../../../main";
const Auth = namespace("Auth");
const Assets = namespace("Assets");

@Component({})
export default class SelectCoin extends Vue {
  @Prop() data!: Array<any>;
  totalRecords: number = 0;
  page: number = 1;
  @Prop() selectedCoin: any;

  @Auth.Getter
  private userGetter!: any;

  @Assets.Action
  private getAssets!: (data: any) => Promise<any>;

  public ShowSelectBox(): void {
    const selectOption = document.querySelector(".selected-option") as any;
    selectOption.parentElement.classList.toggle("active");
  }

  clickOption(item) {
    const selectOption = document.querySelector(".selected-option") as any;
    selectOption.parentElement.classList.toggle("active");
    this.selectedCoin = item;
    eventBus.$emit("selectedCoinEvent", item);
  }

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

}
