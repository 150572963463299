























import Icon from "../../globalUsage/Icon.vue";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  components: { Icon },
})
export default class CoinStatus extends Vue {
  @Prop() text!: string;
  @Prop() subtext!: string;
  @Prop() price!: string;
  @Prop() srcImg!: any;
}
