import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import PostListingService from "@/services/PostListingService";

const storedPostListing = localStorage.getItem("postListing");

@Module({ namespaced: true })
class PostListing extends VuexModule {
  public postListing = storedPostListing ? JSON.parse(storedPostListing) : null;

  @Mutation
  public createPostListingSuccess(postListing: any): void {
    this.postListing = postListing;
  }

  @Mutation
  public createPostListingFailure(): void {
    this.postListing = null;
  }

  @Mutation
  public updatePostListingSuccess(postListing: any): void {
    this.postListing = postListing;
  }

  @Mutation
  public updatePostListingFailure(): void {
    this.postListing = null;
  }

  @Mutation
  public buyPostListingSuccess(postListing: any): void {
    this.postListing = postListing;
  }

  @Mutation
  public buyPostListingFailure(): void {
    this.postListing = null;
  }

  @Mutation
  public sellPostListingSuccess(postListing: any): void {
    this.postListing = postListing;
  }

  @Mutation
  public sellPostListingFailure(): void {
    this.postListing = null;
  }

  @Action({ rawError: true })
  create(data: any): Promise<any> {
    return PostListingService.create(data.access, data).then(
      (postListing) => {
        this.context.commit("createPostListingSuccess", postListing);
        return Promise.resolve(postListing);
      },
      (error) => {
        if(error.response) {
          return Promise.reject(error.response.data);
        }
      }
    );
  }

  @Action({ rawError: true })
  update(data: any): Promise<any> {
    return PostListingService.update(
      data.listing_id,
      data.post_type,
      data.assets_to_trade,
      data.accepted_coins,
      data.partial_binding,
      data.accepts_coin_trading,
      data.accepts_money_transaction,
      data.has_stop_condition,
      data.expiry,
      data.stop_loss_high,
      data.stop_loss_low,
      data.access
    ).then(
      (postListing) => {
        this.context.commit("updatePostListingSuccess", postListing);
        return Promise.resolve(postListing);
      },
      (error) => {
        this.context.commit("updatePostListingFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  buy(data: any): Promise<any> {
    return PostListingService.buy(data.access, data.filter, data.sort).then(
      (postListing) => {
        this.context.commit("buyPostListingSuccess", postListing);
        return Promise.resolve(postListing);
      },
      (error) => {
        this.context.commit("buyPostListingFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  sell(data: any): Promise<any> {
    return PostListingService.sell(data.access, data.filter, data.sort).then(
      (postListing) => {
        this.context.commit("sellPostListingSuccess", postListing);
        return Promise.resolve(postListing);
      },
      (error) => {
        this.context.commit("sellPostListingFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  getPostListingById(data: any): Promise<any> {
    return PostListingService.getPostListingById(
      data.access,
      data.PostListing_id
    ).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  getPostListing(data: any): Promise<any> {
    return PostListingService.getPostListing(
      data.access,
      data.page,
      data.page_size,
      data.filter,
      data.sort
    ).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  @Action({ rawError: true })
  getMyListing(access): Promise<any> {
    return PostListingService.getMyListing(access).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  @Action({ rawError: true })
  getMyListingPast(access): Promise<any> {
    return PostListingService.getMyListingPast(access).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  @Action({ rawError: true })
  getSuggestedListing(data: any): Promise<any> {
    return PostListingService.getSuggestedListing(
      data.access,
      data.post_type,
      data.assets_to_trade,
      data.remaining_coins,
      data.accepted_coins
    ).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
}

export default PostListing;
