






























































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import FirstCard from "./cards/FirstCard.vue";
import SecoundCard from "./cards/SecoundCard.vue";
import SuggestedListings from "./cards/Suggestedlistings.vue";
import ListingIsSuccessful from "./cards/ListingIsSuccessful.vue";
import Confirmation from "./cards/Confirmation.vue";
// for first card
import SelectCoin from "./selectCoin/SelectedCoin.vue";
import AddAmount from "./addAmount.vue";
import AcceptCoinsOrTransaction from "./AcceptCoinsOrTransaction.vue";
// import BTN from "../globalUsage/Button.vue";
import SellConfirmation from "./cards/SellConfirmation.vue";
import { eventBus } from "../../main";
import { namespace } from "vuex-class";
import moment from "moment";
const Auth = namespace("Auth");
const PostListingService = namespace("PostListing");
const Investments = namespace("Investments");
const Assets = namespace("Assets");
import CheckBalance from "../../components/globalUsage/CheckBalance.vue"

@Component({
  components: {
    FirstCard,
    SecoundCard,
    SuggestedListings,
    ListingIsSuccessful,
    SelectCoin,
    AddAmount,
    AcceptCoinsOrTransaction,
    SellConfirmation,
    Confirmation,
    CheckBalance
  },
})
export default class PostListing extends Vue {
  [x: string]: any;
  dialog: false = false;
  isSelected: false = false;
  active: "1" = "1";
  showSuggestListing: boolean = false;
  data: Array<any> = [];
  // @Prop() showCard!: any;
  showSellConfirmation: boolean = false;
  @Prop() Title!: string;
  @Provide()
  resetProcessConfirmed: boolean = false;
  currentCard: number = 1;
  length: 4 = 4;
  count: number = 1;
  selectedCoin: any = [];

  // these field are required to call api
  acceptCoinsTrading: boolean = false;
  accpetMoneyTrasaction: boolean = false;

  allowPartialBuy: boolean = false;
  allowPartialBidding: boolean = false;
  allowStopCondition: boolean = false;
  stopHighPrice: number = 0;
  stopLowPrice: number = 0;

  postType: string = "";
  access: string = "";
  acceptedCoins: any = [];
  myAssets: Array<any> = [];
  date: any;
  time: string = "";
  error: string = "";
  expiryDate: any;
  response: any;
  setRange: boolean = false;
  userBalance : number = 0;

  setRangeClicked(): any {
    this.setRange = !this.setRange;
  }

  get progress(): any {
    return (this.currentCard / this.length) * 100;
  }
  public goBack(): void {
    this.currentCard--;
  }
  public goNext(): void {
    this.currentCard++;
  }
  public closeModal(): any {
    this.dialog = false;
    location.reload();
  }

  public showData(postType: string): any {
    this.selectedCoin = null;
    if (this.postType === postType) {
      this.postType = "";
    } else {
      this.postType = postType;
    }
  }

  public handleExpiryDate(date): any {
    this.date = date;
  }
  public handleExpiryTime(time): any {
    this.time = time;
  }

  public createPostListing(): any {
    let acceptedCoinsPrimaryKey: Array<string> = [];
    this.acceptedCoins.forEach((item) =>
      acceptedCoinsPrimaryKey.push(item.selectedCoin.pk)
    );

    let data = {
      traded_coins: this.count,
      has_stop_condition: this.allowStopCondition,
      post_type: this.postType,
      assets_to_trade: this.selectedCoin.pk,
      accepted_coins: acceptedCoinsPrimaryKey,
      partial_binding: this.allowPartialBidding,
      accepts_coin_trading: this.acceptCoinsTrading,
      accepts_money_transaction: false,
      has_stop_loss_range: true
    };

    let date = moment(this.date).format("YYYY-MM-DD");
    this.expiryDate = new Date(date + "T" + this.time);
    data["expiry"] = this.expiryDate;

    if (this.allowStopCondition && this.setRange) {
      data["stop_loss_high"] = this.stopHighPrice;
      data["stop_loss_low"] = this.stopLowPrice;
    } else if (this.allowStopCondition) {
      if (
        this.postType == "SELL" &&
        this.stopLowPrice <= this.selectedCoin.price
      ) {
        data["stop_loss_high"] = this.selectedCoin.price;
        data["stop_loss_low"] = this.stopLowPrice;
      } else if (
        this.postType == "BUY" &&
        this.stopHighPrice >= this.selectedCoin.price 
      ) {
        data["stop_loss_high"] = this.stopHighPrice;
        data["stop_loss_low"] = this.selectedCoin.price;
      }
    }

    // this.goNext();
    this.access = this.userGetter.access;
    data["access"] = this.access;
    this.create(data)
      .then((res) => {
        if (res.status) {
          this.response = res.data;
          this.goNext();
        } else if (res.status == 400) {
          console.log("bbb", this.error);
          this.error = "nvefn";
        }
      })
      .catch((error) => {
        if (error.status == 400) this.error = error.data?.toString();
        if (this.postType == "SELL") {
          this.error = "Price High";
        } else {
          this.error = "Price Low";
        }
      });
  }

  getAssetsData(page: number) {
    this.getAssets({
      access: this.userGetter.access,
      page: page,
      page_size: 100,
    }).then((response) => {
      this.page = page;
      if (response.data.count) {
        this.totalRecords = response.data.count;
      }
      if (response.data.results) {
        if (page == 1) {
          this.data = [];
        }
        this.data = this.data.concat(response.data.results);
      }
    });
  }

  public changeAcceptCoinsTrading(): void {
    this.acceptCoinsTrading = !this.acceptCoinsTrading;
    if (this.acceptCoinsTrading) {
      this.showSuggestListing = true;
      this.accpetMoneyTrasaction = false;
    } else {
      this.showSuggestListing = false;
    }
  }

  public changeAccpetMoneyTrasaction(): void {
    this.accpetMoneyTrasaction = !this.accpetMoneyTrasaction;
    if (this.accpetMoneyTrasaction) {
      this.acceptCoinsTrading = false;
    }
  }

  public stopHighCondition(value: number): void {
    this.stopHighPrice = value;
  }

  public stopLowCondition(value: number): void {
    this.stopLowPrice = value;
  }

  public changeAllowPartialBuy(): void {
    this.allowPartialBuy = !this.allowPartialBuy;
  }

  public changeAllowPartialBidding(): void {
    this.allowPartialBidding = !this.allowPartialBidding;
  }

  public changeAllowStopCondition(): void {
    this.allowStopCondition = !this.allowStopCondition;
  }

  @Assets.Action
  private getAssets!: (data: any) => Promise<any>;

  @Auth.Getter
  private userGetter!: any;

  @Auth.Action
  private getBalance!: (data: any) => Promise<any>;

  // Buy
  @PostListingService.Action
  private buy!: (data: any) => Promise<any>;

  @PostListingService.Mutation
  private buyPostListingSuccess!: (success: any) => void;

  @PostListingService.Mutation
  private buyPostListingFailure!: (error: any) => void;

  // Sell
  @PostListingService.Action
  private sell!: (data: any) => Promise<any>;

  @PostListingService.Mutation
  private sellPostListingSuccess!: (success: any) => void;

  @PostListingService.Mutation
  private sellPostListingFailure!: (error: any) => void;

  // Create
  @PostListingService.Action
  private create!: (data: any) => Promise<any>;

  @PostListingService.Mutation
  private createPostListingSuccess!: (success: any) => void;

  @PostListingService.Mutation
  private createPostListingFailure!: (error: any) => void;

  // Update
  @PostListingService.Action
  private update!: (data: any) => Promise<any>;

  @PostListingService.Mutation
  private updatePostListingSuccess!: (success: any) => void;

  @PostListingService.Mutation
  private updatePostListingFailure!: (error: any) => void;

  @Investments.Action
  private getInvestments!: (data: any) => Promise<any>;

  increment() {
    this.count++;
  }

  decrement() {
    if (this.count > 0) {
      this.count--;
    }
  }

  getInvestmentData() {
    let data = {
      page: 1,
      page_size: 5,
      access: this.userGetter.access,
    };
    this.getInvestments(data).then((res) => {
      if (res.data.results.length) {
        this.myAssets = [];
        res.data.results.forEach((element) => {
          this.myAssets.push(element.asset);
        });
      }
    });
  }

  created() {
     this.getBalance({
        access: this.userGetter.access,
        }).then((res) => {
        if (res) {
        this.userBalance = res.data.balance
      }});
    this.getInvestmentData();
    this.getAssetsData(1);
    eventBus.$on("selectedCoinEvent", (data: any) => {
      this.selectedCoin = data;
    });
  }
}
