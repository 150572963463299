










































import { Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Coin from "@/components/coin/Coin.vue";
import PostListing from "@/components/postListing/PostListing.vue";

const Auth = namespace("Auth");
const Investments = namespace("Investments");
const Assets = namespace("Assets");

@Component({
  components: { Coin, PostListing },
})
export default class TradingPortal extends Mixins() {
  HeaderPage: string = "Trading portal";
  BtnText: string = "Post listing";

  totalRecord: number = 0;
  page: number = 1;
  data: any = [];

  @Auth.Getter
  private userGetter!: any;

  @Investments.Action
  private get!: (data: string) => Promise<any>;

  @Investments.Mutation
  private getInvestmentsFailure!: (error: any) => void;

  @Investments.Mutation
  private getInvestmentsSuccess!: (investments: any) => any;

  @Investments.Action
  private getTotal!: (data: string) => Promise<any>;

  @Investments.Mutation
  private getTotalInvestmentsFailure!: (error: any) => void;

  @Investments.Mutation
  private getTotalInvestmentsSuccess!: (totalInvestments: any) => any;

  @Assets.Action
  private getAssets!: (data: any) => Promise<any>;

  @Investments.Action
  private getInvestments!: (data: any) => Promise<any>;

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  getInvestmentData() {
    let data = {
      page: 5,
      page_size: 4,
      access: this.userGetter.access,
    };
    this.getInvestments(data);
  }

  getAssetsData(page: number) {
    this.getAssets({
      access: this.userGetter.access,
      page: page,
      page_size: 12,
    }).then((response) => {
      if (response.data) {
        if (page == 1) {
          this.data = [];
        }
        this.page = page;
        this.totalRecord = response.data.count;
        this.data = this.data.concat(response.data.results);
      }
    });
  }

  mounted() {
    // this.get(this.userGetter)
    //   .then((res) => this.getInvestmentsSuccess(res.data))
    //   .catch((e) => this.getInvestmentsFailure(e));

    // this.getTotal(this.userGetter)
    //   .then((res) => this.getTotalInvestmentsSuccess(res.data))
    //   .catch((e) => this.getTotalInvestmentsFailure(e));

    this.getAssetsData(1);
  }
}
