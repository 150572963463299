









































import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "../../../../../globalUsage/Icon.vue";
@Component({
  components: { Icon },
})
export default class TradeRejected extends Vue {
  @Prop() closeModal!: any;
}
