import Vue from "vue";
import Vuex from "vuex";
import Auth from "./modules/auth.module";
import Socket from "./modules/socket.module";
import Wallet from "./modules/wallet.module";
import Assets from "./modules/assets.module";
import Users from "./modules/users.module";
import UsersService from "./modules/users.module";
import PostListing from "./modules/postlisting.module";
import Investments from "./modules/investments.module";
import InvestmentOrders from "./modules/investmentOrders.module";
import Trading from "./modules/trading.module";
import Notifications from "./modules/notifications.module";
import Profile from "./modules/profile.module";
import TradingAsset from "./modules/tradingProposal.module";
import TokenTransferTransaction from "./modules/tokenTransferTransaction.module";
import InitialCoinOffering from "./modules/initialCoinOffering.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loader: false
  },
  mutations: {},
  actions: {},
  modules: {
    Auth,
    Socket,
    Wallet,
    Assets,
    Users,
    PostListing,
    UsersService,
    Investments,
    InvestmentOrders,
    Trading,
    Notifications,
    Profile,
    TradingAsset,
    TokenTransferTransaction,
    InitialCoinOffering
  }
});
