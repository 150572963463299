

















































import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "../../../globalUsage/Icon.vue";
@Component({
  components: { Icon },
})
export default class Sort extends Vue {
  @Prop() closeModal!: any;
  @Prop() buyData!: any;

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }
}
