












































































































import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "../../../../../globalUsage/Icon.vue";
import AddAmount from "../../../../../postListing/addAmount.vue";
import Confirmation from "./cards/ConfirmationBuy.vue";
import SuccessfulyBought from "./cards/SuccessfulyBought.vue";
import { namespace } from "vuex-class";
import { eventBus } from "@/main";
const Auth = namespace("Auth");
const Investments = namespace("Investments");
const Wallet = namespace("Wallet");

@Component({
  components: { Icon, AddAmount, Confirmation, SuccessfulyBought },
})
export default class BuyCoins extends Vue {
  @Prop() assetData!: any;
  buyData: any = {};

  dialog: false = false;
  currentScreen: number = 1;
  length: 3 = 3;
  count = 1;
  PurchaseDetail: boolean = true;

  @Auth.Getter
  private userGetter!: any;

  @Investments.Action
  private buy!: (data: any) => Promise<any>;

  @Wallet.Mutation
  private getWalletSuccess!: (wallet: any) => any;

  @Wallet.Action
  private get!: (data: any) => Promise<any>;

  public closeModal(): any {
    this.assetData.available_coins =
      this.assetData.available_coins + this.count;
    this.currentScreen = 1;
    return (this.dialog = false);
  }

  public goNext(): void {
    if (this.currentScreen == 2) {
      this.buy({
        access: this.userGetter.access,
        asset_id: this.assetData.pk,
        asset_quantity: this.count,
      }).then((response) => {
        this.get({ access: this.userGetter.access }).then((res) => {
          eventBus.$emit("changeAssetData", response.data);
          this.getWalletSuccess(res.data);
          this.buyData = response.data;
          this.currentScreen++;
        });
      });
    } else {
      this.currentScreen++;
    }
  }
  public goBack(): void {
    this.currentScreen--;
  }

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  increment() {
    this.count++;
    this.PurchaseDetail = true;
    // this.Hightlight();
  }
  decrement() {
    if (this.count > 0) {
      this.count--;
    }

    if (this.count == 0) {
      this.PurchaseDetail = false;
    }
    // this.Hightlight();
  }

  //HighLight the button..
  // Hightlight() {
  //   const addHighlight = document.querySelectorAll(".addHighlight");

  //   function togglebtn(this: any) {
  //     this.classList.add("active");
  //   }

  //   addHighlight.forEach((btn: any) =>
  //     btn.addEventListener("click", togglebtn)
  //   );
  // }
}
