













import Vue from "vue";
import Component from "vue-class-component";
import LoginForm from "../components/auth/LoginForm.vue";
import SignUpForm from "../components/auth/SignUpForm.vue";

@Component({
  components: { LoginForm, SignUpForm }
})
export default class Auth extends Vue {
  accountExists: boolean = true;

  activateSignUpComponent(): void {
    this.accountExists = false;
  }

  activateLoginComponent(): void {
    this.accountExists = true;
  }
}
