



















































































import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "../../../../../globalUsage/Icon.vue";
@Component({
  components: { Icon },
})
export default class Sort extends Vue {
  dialog: false = false;
  @Prop() goNext!: void;
  @Prop() goBack!: void;
  @Prop() closeModal!: void;
  @Prop() selectedCoin: any;
  @Prop() data: any;
  @Prop() quantity!: number;
  @Prop() count!: number;
  @Prop() buyPortion!: number;
  @Prop() error!: any;
  @Prop() loader!: any;

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }
}
