import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import InitialCoinOfferingService from "@/services/InitialCoinOfferingService";

const storedInitialCoinOfferingData = localStorage.getItem("InitialCoinOfferingSuccess");
const storedBiddingData = localStorage.getItem("BiddingSuccess");
const storedIcoId = localStorage.getItem("icoId");
const storedPaymentData = localStorage.getItem("PaymentSuccess");
const storedPaypalBearerToken = localStorage.getItem("paypalBearerToken")

@Module({ namespaced: true })
class InitialCoinOffering extends VuexModule {
    public InitialCoinOfferingData = storedInitialCoinOfferingData ? JSON.parse(storedInitialCoinOfferingData) : null;
    public BiddingData = storedBiddingData ? JSON.parse(storedBiddingData) : null;
    public PaymentData = storedPaymentData ? JSON.parse(storedPaymentData) : null;
    public ico_id: number | null = storedIcoId ? JSON.parse(storedIcoId) : null;

    
  get icoIdGetter() {
    return this.ico_id;
  }

    @Mutation
    public setICOId(icoId: number): void {
        this.ico_id = icoId;
        localStorage.setItem("icoId", JSON.stringify(icoId));
    }

    @Mutation
    public getInitialCoinOfferingSuccess(InitialCoinOfferingSuccess: any): void {
        this.InitialCoinOfferingData = InitialCoinOfferingSuccess;
        localStorage.setItem("InitialCoinOfferingSuccess", JSON.stringify(InitialCoinOfferingSuccess));
    }

    @Mutation
    public getInitialCoinOfferingFailure(error: any): void {
        console.error(error);
        this.InitialCoinOfferingData = null;
    }

    @Mutation
    public createBiddingSuccess(BiddingSuccess: any): void {
        this.BiddingData = BiddingSuccess;
        localStorage.setItem("BiddingSuccess", JSON.stringify(BiddingSuccess));
    }

    @Mutation
    public paymentSuccess(PaymentSuccess: any): void {
        this.PaymentData = PaymentSuccess;
        localStorage.setItem("PaymentSuccess", JSON.stringify(PaymentSuccess));
    }

    @Mutation
    public createBiddingFailure(error: any): void {
        console.error(error);
        this.BiddingData = null;
    }

    @Action({ commit: "setICOId" })
    public async setICOIdAction(icoId: number): Promise<number> {
        return icoId;
    }


    @Action({ rawError: true })
    getInitialCoinOffering(): Promise<any> {
        return InitialCoinOfferingService.getInitialCoinOffering().then(
            InitialCoinOfferingSuccess => {
                this.context.commit("getInitialCoinOfferingSuccess", InitialCoinOfferingSuccess);
                return Promise.resolve(InitialCoinOfferingSuccess);
            },
            error => {
                this.context.commit("getInitialCoinOfferingFailure");
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                return Promise.reject(message);
            }
        );
    }

    @Action({ rawError: true })
    getBiddingAssetQuantityCount(data: any): Promise<any> {
        return InitialCoinOfferingService.getBiddingAssetQuantityCount(data.ico).then(
            InitialCoinOfferingSuccess => {
                this.context.commit("getInitialCoinOfferingSuccess", InitialCoinOfferingSuccess);
                return Promise.resolve(InitialCoinOfferingSuccess);
            },
            error => {
                this.context.commit("getInitialCoinOfferingFailure");
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                return Promise.reject(message);
            }
        );
    }

    @Action({ rawError: true })
    createBidding(data: {
        access: string;
        quantity: number;
        asset: number;
        ico: number;
    }): Promise<any> {
        return InitialCoinOfferingService.createBidding(data.access, data).then(
            (BiddingSuccess) => {
                this.context.commit("createBiddingSuccess", BiddingSuccess);
                return Promise.resolve(BiddingSuccess);
            },
            (error) => {
                if(error.response) {
                    return Promise.reject(error.response.data);
                  }
            }
        );
    }

    @Action({ rawError: true })
    payWithPaypal(data: {
        access: string;
        bidding_id: number;
      }): Promise<any> {
        return InitialCoinOfferingService.payWithPaypal(data.access, data).then(
            (PaymentSuccess) => {
                this.context.commit("paymentSuccess", PaymentSuccess);
                return Promise.resolve(PaymentSuccess);
            },
            (error) => {
                if(error.response) {
                    return Promise.reject(error.response.data);
                  }
            }
        );
    }

}

export default InitialCoinOffering;