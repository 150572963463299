






















































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import CoinDetail from "../../coinsDetails/CoinDetail.vue";
import SeeDetails from "./see-details/SeeDetails.vue";
@Component({
  components: { CoinDetail, SeeDetails }
})
export default class CurrentProfileTab extends Vue {
  @Prop() coinType!: string;
  @Prop() totalAmount!: string;
  @Prop() totalAmountCoins!: string;
  @Prop() currentPrice!: string;
  @Prop() profitLose!: string;
  @Prop() seeDetails!: string;
  @Prop() oil!: string;
  @Prop() gas!: string;
  @Prop() gold18!: string;
  @Prop() silver!: string;
  // @Prop() coinState!: string;
}
