





















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Icon from "../../globalUsage/Icon.vue";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const InvestmentOrders = namespace("InvestmentOrders");

@Component({
  components: { Icon }
})
export default class History extends Vue {
  @Prop() id!: number;
  dialog: boolean = false;
  details: any = {};

  @Auth.Getter
  private userGetter!: any;

  @InvestmentOrders.Action
  private getInvestmentOrderDetails!: (data: any) => Promise<any>;

  displayDateTime(value: any) {
    if (value == null || value == "") return "";

    return new Date(value).toLocaleString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  getDetails() {
    this.getInvestmentOrderDetails({
      id: this.id,
      access: this.userGetter.access,
    }).then((response) => {
      this.details = response.data;
    });
  }
}
