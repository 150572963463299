













































import { Vue, Component } from "vue-property-decorator";
import Icon from "../../globalUsage/Icon.vue";
import CoinPerformance from "./CoinPerformance.vue";
import CoinStatus from "./CoinStatus.vue";
import { namespace } from "vuex-class";
import { ASSETS_ENDPOINTS } from "../../../services/ApiEndpoints";

const Assets = namespace("Assets");
const Investments = namespace("Investments");
const Auth = namespace("Auth");

@Component({
  components: { Icon, CoinPerformance, CoinStatus },
})
export default class MarketPlace extends Vue {
  singlePointPriceGraphLiveData = {
    chart: {
      type: "Spline",
      data: [[169024300000, 0, 0, 0]],
      settings: {
        color: "#33df90",
        lineWidth: 2,
        ZOOM: 3,
      },
      grid: {},
    },
  };
  dialog: false = false;
  CurrentScreen: 1 = 1;
  length: 2 = 2;
  data: any = [];
  page: number = 1;
  totalRecord: number = 0;
  asset_id: number = 1;
  asset: any = {};
  investmentsData: any = {};
  assetsLiveData: any = [];

  @Assets.Action
  private getAssets!: (data: any) => Promise<any>;

  @Assets.Action
  private getAssetById!: (data: any) => Promise<any>;

  @Investments.Action
  private totalInvestmentByAsset!: (data: any) => Promise<any>;

  @Auth.Getter
  private userGetter!: any;

  @Assets.Action
  private getAssetsLivePrice!: (data: any) => Promise<any>;

  getAssetsLivePriceData(endPoint: any) {
    this.getAssetsLivePrice({
      access: this.userGetter.access,
      apiEndpoint: endPoint,
    }).then((response) => {
      if (response.data) {
        this.assetsLiveData = [];
        this.assetsLiveData = response.data.reverse();
        this.singlePointPriceGraphLiveData.chart.data = [];
        this.assetsLiveData.forEach((item) => {
          this.singlePointPriceGraphLiveData.chart.data.push([
            Date.parse(item.date),
            parseFloat(item.open),
            parseFloat(item.high),
            parseFloat(item.low),
            parseFloat(item.price),
            parseFloat(item.vol),
          ]);
        });
      }
    });
  }

  getSinglePointPriceGraph(item) {
    this.getAssetsLivePriceData(ASSETS_ENDPOINTS[item])
  }

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  getAssetsData(page: number) {
    this.getAssets({
      access: this.userGetter.access,
      page: page,
      page_size: 12,
    }).then((response) => {
      if (response.data) {
        if (page == 1) {
          this.data = [];
        }
        this.page = page;
        this.totalRecord = response.data.count;
        this.data = this.data.concat(response.data.results);
      }
    });
  }

  mounted() {
    this.getAssetsData(1);
  }
  get progress(): any {
    return (this.CurrentScreen / this.length) * 100;
  }
  public goBack(): void {
    this.CurrentScreen--;
  }
  public goNext(asset_id: number): void {
    this.CurrentScreen++;
    this.asset_id = asset_id;

    this.getAssetById({
      asset_id: this.asset_id,
      access: this.userGetter.access,
    }).then((response) => {
      if (response.data) {
        this.asset = response.data;
        this.getSinglePointPriceGraph(this.asset.name);
      }
    });

    this.totalInvestmentByAsset({
      asset_id: this.asset_id,
      access: this.userGetter.access,
    }).then((res) => {
      if (res.data) {
        this.investmentsData = res.data[0];
        this.investmentsData.asset = this.asset;
      }
    });
  }

  public closeModal(): any {
    this.dialog = false;
    // location.reload();
    // this.mounted();
    this.$router.go(0);
  }

  // created() {
  //   if (this.asset_id != null) {
  //     this.getAssetById({
  //       asset_id: this.asset_id,
  //       access: this.userGetter.access,
  //     }).then((response) => {
  //       if (response.data) {
  //         this.asset = response.data;
  //   console.log(this.asset_id)

  //       }
  //     });
  //   }
  // }
}
