


















































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Icon from "../../globalUsage/Icon.vue";
import Detail from "./Detail.vue";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const InvestmentOrders = namespace("InvestmentOrders");

@Component({
  components: { Icon, Detail }
})
export default class History extends Vue {
  dialog: false = false;

  totalRecords: number = 0;
  page: number = 1;
  data: any = [];

  @Auth.Getter
  private userGetter!: any;

  @InvestmentOrders.Action
  private getInvestmentOrders!: (data: any) => Promise<any>;

  displayDateTime(value: any) {
    if (value == null || value == "") return "";

    return new Date(value).toLocaleString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  getInvestmentOrderData(page: number) {
    this.getInvestmentOrders({
      access: this.userGetter.access,
      page: page,
      page_size: 100,
    }).then((response) => {
      this.page = page;
      if (response.data.count) {
        this.totalRecords = response.data.count;
      }
      if (response.data.results) {
        if (page == 1) {
          this.data = [];
        }
        this.data = this.data.concat(response.data.results);
      }
    });
  }

  created() {
    this.getInvestmentOrderData(1);
  }
}
