

















































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Wallet = namespace("Wallet");
const Auth = namespace("Auth");

@Component
export default class CoinDetail extends Vue {
  dialog: false = false;
  @Prop() seeDetails!: string;
  @Prop() portfolioDetails!: string;
  @Prop() cardName!: string;
  @Prop() numberCoins!: string;
  @Prop() totalValue!: string;
  @Prop() totalValueNumber!: string;
  @Prop() lastActivity!: string;
  @Prop() lastActivityDate!: string;
  @Prop() dateTime!: string;
  @Prop() src!: any;
  @Prop() closeIcon!: any;
  @Prop() activity!: string;
  @Prop() amount!: string;
  @Prop() dateTimeFull!: string;
  @Prop() activityStateSelling!: string;
  @Prop() activityStateBuying!: string;
  @Prop() balance!: string;
  @Prop() price!: string;
  @Prop() topUP!: string;

  @Auth.Getter
  private userGetter!: any;

  @Wallet.Getter
  private walletGetter!: any;

  @Wallet.Action
  private get!: (data: any) => Promise<any>;

  @Wallet.Mutation
  private getWalletFailure!: (error: any) => void;

  @Wallet.Mutation
  private getWalletSuccess!: (wallet: any) => any;

  mounted() {
    this.get({ access: this.userGetter.access }) // email, password,
      .then(res => this.getWalletSuccess(res.data))
      .catch(e => this.getWalletFailure(e));
  }
}
