




























































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "../../../../../../globalUsage/Icon.vue";
import { namespace } from "vuex-class";
const Wallet = namespace("Wallet");

@Component({
  components: { Icon },
})
export default class BuyConfirmation extends Vue {
  dialog: false = false;
  @Prop() goNext!: void;
  @Prop() goBack!: void;
  @Prop() closeModal!: void;
  @Prop() assetData!: any;
  @Prop() count!: number;

  CreditCardForm: boolean = false;
  WalletForm: boolean = false;

  @Wallet.State("wallet")
  private userWallet!: any;

  public ShowSelectBox(): void {
    const selectOption = document.querySelector(".selected-option") as any;
    selectOption.parentElement.classList.toggle("active");
    // list
    const options = document.querySelectorAll(".option") as any;

    options.forEach((option: any) => {
      option.addEventListener("click", () => {
        selectOption.innerHTML = option.innerHTML;
        selectOption.parentElement?.classList.remove("active");
      });
    });
  }

  CreditInfo(): void {
    this.CreditCardForm = true;
  }
  WalletSelect(): void {
    this.CreditCardForm = false;
    this.WalletForm = true;
  }

  PaypalForm(): void {
    this.WalletForm = false;
  }

  BankUnable(): void {
    this.CreditCardForm = false;
  }
}
