import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import InvestmentsService from "@/services/InvestmentsService";

const storedInvestments = localStorage.getItem("investments");
const storedTotalInvestments = localStorage.getItem("totalInvestments");

@Module({ namespaced: true })
class Investments extends VuexModule {
  public investments = storedInvestments ? JSON.parse(storedInvestments) : null;
  public totalInvestments = storedTotalInvestments
    ? JSON.parse(storedTotalInvestments)
    : null;

  get investmentsGetter() {
    return this.investments;
  }

  get totalInvestmentsGetter() {
    return this.totalInvestments;
  }

  @Mutation
  public getInvestmentsSuccess(investments: any): void {
    this.investments = investments;
    localStorage.setItem("investments", JSON.stringify(investments));
  }

  @Mutation
  public getInvestmentsFailure(error: any): void {
    console.error(error);
    this.investments = null;
  }

  @Mutation
  public getTotalInvestmentsSuccess(totalInvestments: any): void {
    this.totalInvestments = totalInvestments;
    localStorage.setItem("totalInvestments", JSON.stringify(totalInvestments));
  }

  @Mutation
  public getTotalInvestmentsFailure(error: any): void {
    console.error(error);
    this.totalInvestments = null;
  }

  @Mutation
  public sellInvestmentsSuccess(investments: any): void {
    this.investments = investments;
    localStorage.setItem("investments", JSON.stringify(investments));
  }

  @Mutation
  public sellInvestmentsFailure(error: any): void {
    console.error(error);
    this.investments = null;
  }

  @Mutation
  public buyInvestmentsSuccess(investments: any): void {
    this.investments = investments;
    localStorage.setItem("investments", JSON.stringify(investments));
  }

  @Mutation
  public buyInvestmentsFailure(error: any): void {
    console.error(error);
    this.investments = null;
  }

  @Action({ rawError: true })
  get(data: any): Promise<any> {
    return InvestmentsService.get(
      data.asset,
      data.purchased_price,
      data.purchased_quantity,
      data.access
    ).then(
      investments => {
        this.context.commit("getInvestmentsSuccess", investments);
        return Promise.resolve(investments);
      },
      error => {
        this.context.commit("getInvestmentsFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  getTotal(data: any): Promise<any> {
    return InvestmentsService.getTotal(data.query, data.access).then(
      totalInvestments => {
        this.context.commit("getTotalInvestmentsSuccess", totalInvestments);
        return Promise.resolve(totalInvestments);
      },
      error => {
        this.context.commit("getTotalInvestmentsFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  sell(data: any): Promise<any> {
    return InvestmentsService.sell(
      data.asset_id,
      data.asset_quantity,
      data.access
    ).then(
      investments => {
        this.context.commit("sellInvestmentsSuccess", investments);
        return Promise.resolve(investments);
      },
      error => {
        this.context.commit("sellInvestmentsFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  buy(data: any): Promise<any> {
    return InvestmentsService.buy(
      data.asset_id,
      data.asset_quantity,
      data.access
    ).then(
      investments => {
        this.context.commit("buyInvestmentsSuccess", investments);
        return Promise.resolve(investments);
      },
      error => {
        this.context.commit("buyInvestmentsFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  getInvestments(data: any): Promise<any> {
    return InvestmentsService.getInvestments(
      data.page,
      data.page_size,
      data.access
    ).then(
      investments => {
        this.context.commit("getInvestmentsSuccess", investments);
        return Promise.resolve(investments);
      },
      error => {
        this.context.commit("getInvestmentsFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  totalInvestmentByAsset(data: any): Promise<any> {
    return InvestmentsService.totalInvestmentByAsset(
      data.asset_id,
      data.access
    ).then(
      investments => {
        return Promise.resolve(investments);
      },
      error => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
}

export default Investments;
