








































import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "../../../../../globalUsage/Icon.vue";
@Component({
  components: { Icon },
})
export default class Sort extends Vue {
  @Prop() closeModal!: any;
}
