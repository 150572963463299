










































































































































































































































import { Vue, Prop, Component } from "vue-property-decorator";
import Icon from "../../globalUsage/Icon.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import SelectTime from "./SelectTime.vue";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const Assets = namespace("Assets");
const PostListingService = namespace("PostListing");

@Component({
  components: {
    Icon,
    DatePicker,
    SelectTime,
  },
})
export default class NoSuggestedListing extends Vue {
  @Prop() goBack!: void;
  @Prop() goNext!: void;
  @Prop() closeModal!: void;
  @Prop() selectedCoin!: any;
  @Prop() acceptedCoins!: any;
  @Prop() count!: number;
  @Prop() time!: string;
  @Prop() postType!: string;
  @Prop() date!: string;
  @Prop() changeAllowPartialBidding!: void;
  @Prop() changeAllowStopCondition!: void;
  @Prop() handleExpiryDate!: void;
  @Prop() handleExpiryTime!: void;
  @Prop() stopHighPrice!: string;
  @Prop() stopLowPrice!: string;
  @Prop() stopHighCondition!: void;
  @Prop() stopLowCondition!: void;
  @Prop() error!: string;
  @Prop() setRange: any;
  @Prop() setRangeClicked!: void;

  showLoading: boolean = false;
  show: false = false;
  selectedDate: string = "";
  data: any = [];
  totalRecords: number = 0;
  page: number = 1;
  allowStopCondition: boolean = false;
  allowPartialBidding: boolean = false;
  lowHighRange: boolean = false;
  selectPrice: boolean = true;

  // setRangeClicked(): any {
  //   this.setRange = !this.setRange;
  // }

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  @Auth.Getter
  private userGetter!: any;

  @Assets.Action
  private getAssets!: (data: any) => Promise<any>;

  @PostListingService.Mutation
  private createPostListingFailure!: (error: any) => void;

  addCoin() {
    this.acceptedCoins.push({
      selectedCoin: null,
      currentPrice: 0,
      currentProfit: 0,
      conversionCoin: 0,
      showCoinList: false,
    });
  }

  clickCoin(item, index) {
    this.acceptedCoins[index].selectedCoin = item;
    this.acceptedCoins[index].showCoinList = false;
    this.setConversionCoin();
  }

  setConversionCoin() {
    let amount = 0;
    if (this.acceptedCoins.length > 0) {
      amount =
        (this.selectedCoin.price * this.count) / this.acceptedCoins.length;
      if (isNaN(amount)) amount = 0;

      this.acceptedCoins.forEach((element) => {
        let conversionCoin = 0;
        if (element.selectedCoin.price != 0) {
          conversionCoin = amount / element.selectedCoin.price;
          if (isNaN(conversionCoin)) conversionCoin = 0;
        }

        element.currentPrice =
          element.selectedCoin.price < 0
            ? "-$" + element.selectedCoin.price * -1
            : "$" + element.selectedCoin.price;
        element.currentProfit = 0;
        element.conversionCoin = Number(conversionCoin.toFixed(2));
      });
    }
  }

  getAssetsData(page: number) {
    this.getAssets({
      access: this.userGetter.access,
      page: page,
      page_size: 100,
    }).then((response) => {
      this.page = page;
      if (response.data.count) {
        this.totalRecords = response.data.count;
      }
      if (response.data.results) {
        if (page == 1) {
          this.data = [];
        }
        this.data = this.data.concat(response.data.results);
      }
    });
  }

  created() {
    this.getAssetsData(1);
  }
}
