import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import WalletService from "@/services/WalletService";

const storedWallet = localStorage.getItem("wallet");

@Module({ namespaced: true })
class Wallet extends VuexModule {
  public wallet = storedWallet ? JSON.parse(storedWallet) : null;

  get walletGetter() {
    return this.wallet;
  }

  @Mutation
  public getWalletSuccess(wallet: any): void {
    this.wallet = wallet;
    localStorage.setItem("wallet", JSON.stringify(wallet));
  }

  @Mutation
  public getWalletFailure(error: any): void {
    console.error(error);
    this.wallet = null;
  }

  @Action({ rawError: true })
  get(data: any): Promise<any> {
    return WalletService.get(data.access).then(
      // data.email, data.password,
      wallet => {
        this.context.commit("getWalletSuccess", wallet);
        return Promise.resolve(wallet);
      },
      error => {
        this.context.commit("getWalletFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
}

export default Wallet;
