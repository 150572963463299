



















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
@Component
export default class CoinDetail extends Vue {
  dialog: false = false;
  // @Prop() seeDetails!: string;
  // @Prop() portfolioDetails!: string;
  // @Prop() cardName!: string;
  // @Prop() numberCoins!: string;
  // @Prop() totalValue!: string;
  // @Prop() totalValueNumber!: string;
  // @Prop() lastActivity!: string;
  // @Prop() lastActivityDate!: string;
  // @Prop() dateTime!: string;
  // @Prop() src!: any;
  @Prop() closeIcon!: any;
  @Prop() warning!: string;
  // @Prop() activity!: string;
  // @Prop() amount!: string;
  // @Prop() dateTimeFull!: string;
  // @Prop() activityStateSelling!: string;
  // @Prop() activityStateBuying!: string;
  @Prop() logoutAction!: any;
}
