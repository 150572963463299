























































































import { Vue, Component } from "vue-property-decorator";
import service from "../components/landing/cards/service.vue";
import token from "../components/landing/cards/token.vue";
import roadmap from "../components/landing/roadmap/roadmap.vue";
import whitepaper from "../components/landing/cards/whitepaper.vue";
import contentUS from "../components/landing/form/contentUS.vue";
import landingPageFooter from "../components/landing/landingPageFooter/landingPageFooter.vue";
import landingPageHeader from "../components/landing/landingPageHeader/landingPageHeader.vue";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const InitialCoinOffering = namespace("InitialCoinOffering");

@Component({
  components: {
    service,
    token,
    roadmap,
    whitepaper,
    contentUS,
    landingPageFooter,
    landingPageHeader,
  },
})
export default class LandingPage extends Vue {
  initialCoinOfferingData: string[] = [];
  serviceData = [
    {
      heading: "Register New Account",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla neque quam, max imus ut accumsan ut, posuere sit.",
      img: "builder.svg",
      id: 1,
    },
    {
      heading: "Setup Account Info",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla neque quam, max imus ut accumsan ut, posuere sit.",
      img: "cover.svg",
      id: 2,
    },
    {
      heading: "Start Buying & Selling",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla neque quam, max imus ut accumsan ut, posuere sit.",
      img: "search.svg",
      id: 3,
    },
  ];

  @Auth.Getter
  private userGetter!: any;

  @InitialCoinOffering.Action
  private getInitialCoinOffering!: () => Promise<any>;

  getInitialCoin() {
    this.getInitialCoinOffering().then((response) => {
      if (response.data) {
        this.initialCoinOfferingData = response.data.results;
      }
    });
  }

    mounted(): void {
    this.getInitialCoin()
    console.log("initialCoinOfferingData",this.initialCoinOfferingData)
  }
}
