





































































import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const InitialCoinOffering = namespace("InitialCoinOffering");

@Component
export default class LandingPage extends Vue {
  timer: any;
  bidding_asset_quantity_count: number = 0;
  days = 0;
  hours = 0;
  minutes = 0;
  seconds = 0;
  tokenDate = {};
  warningButton: boolean = false;
  progressLinearValue : number = 0;
  @Prop() itemData!: any;
  targetDate = new Date(this.itemData.end_date).getTime();

  @Auth.State("status")
  private UserIsLoggedIn!: any;

  @Auth.Getter
  private userGetter!: any;

  @InitialCoinOffering.Action
  private getBiddingAssetQuantityCount!: (data) => Promise<any>;

  @InitialCoinOffering.Action
  private setICOIdAction!: (icoId: number) => Promise<number>;

  getInitialCoin() {
    this.getBiddingAssetQuantityCount({
      ico: this.itemData.id,
    }).then((response) => {
      if (response.data) {
        this.bidding_asset_quantity_count = response.data.bidding_asset_quantity_count ;
        this.progressLinearValue =  (response.data.bidding_asset_quantity_count * 100)/this.itemData.max_limit ;
      }
    });
  }

  mounted(): void {
    this.getInitialCoin();
  }

  setIcoId():void {
     this.setICOIdAction(this.itemData.id)
      .then((response) => {
        console.log("Action executed:", response);
      })
  }

  login() {
    this.setIcoId()
    this.$router.push('/landing-page/login');
  }

  created() {
    // Update the countdown every second
    this.timer = setInterval(() => {
      const currentDate = new Date().getTime();
      const timeRemaining = this.targetDate - currentDate;

      if (timeRemaining <= 0) {
        // Countdown has reached its target, you can handle this event here
        clearInterval(this.timer);
      } else {
        // Calculate days, hours, minutes, and seconds remaining
        this.days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        this.hours = Math.floor(
          (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        this.minutes = Math.floor(
          (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
        );
        this.seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
        this.tokenDate = {
          days: this.days,
          hours: this.hours,
          minutes: this.minutes,
          seconds: this.seconds,
        };
      }
    }, 1000);
  }

  beforeDestroy() {
    // Clean up the timer when the component is destroyed
    clearInterval(this.timer);
  }
}
