


















































import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
  components: { DatePicker },
})
export default class dataPicker extends Vue {
  @Prop() handleDate1!: any;
  @Prop() date1!: any;
  @Prop() handleDate2!: any;
  @Prop() date2!: any;
}
