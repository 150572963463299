


















import { Vue, Component } from "vue-property-decorator";

@Component
export default class Buttons extends Vue {
  isSelected: false = false;
  // selection:true = true
  active: "1" = "1";
}
