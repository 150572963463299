



























import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "../../components/globalUsage/Icon.vue";
// import { namespace } from "vuex-class";
// const TradingAsset = namespace("TradingAsset");
// const Auth = namespace("Auth");
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const WalletService = namespace("Wallet");

@Component({
  components: { Icon },
})
export default class acceptingTradeRequest extends Vue {
  @Prop() closeModal!: any;
  @Prop() goNext!: any;
  @Prop() userBalance!: any;
  showBalance: boolean = false;
  walletData: any = [];

  @WalletService.Action
  private get!: (data: any) => Promise<any>;

  @Auth.Getter
  private userGetter!: any;

  checkBalance() {
    this.showBalance = true;
  }

    created() {

    this.get({
      access: this.userGetter.access,
    }).then((res) => {
      if (res) {
        this.walletData = [];
        this.walletData = res.data.results;
      }
    })}
   
}
