















































































































import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "../../../../globalUsage/Icon.vue";
@Component({
  components: { Icon },
})
export default class FilterAssets extends Vue {
  // dialog: false = false;
  isSelected: false = false;
  active: "1" = "1";
  @Prop() AssetsData!: any;
  @Prop() filterData!: any;
  @Prop() assetsNamefunction!: any;
  @Prop() getFiletrData!: any;
  @Prop() getfilterItem!: any;
  @Prop() dialog!: any;
  @Prop() partialBindingfunction!: any;
  @Prop() availableForYourCoinsfunction!: any;
  @Prop() profitTradeOnlyfunction!: any;
  @Prop() loseTradeOnlyfunction!: any;
}
