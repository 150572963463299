import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=064c2228&scoped=true&"
import script from "./Button.vue?vue&type=script&lang=ts&"
export * from "./Button.vue?vue&type=script&lang=ts&"
import style0 from "./Button.vue?vue&type=style&index=0&id=064c2228&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "064c2228",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
installComponents(component, {VChip,VChipGroup})
