import axios from "axios";
import { API_ENDPOINTS } from "./ApiEndpoints";

class TradingProposal {
  getProposal(access: string) {
    return axios.get(
      API_ENDPOINTS.TradingProposal.TradingProposal,
      {
        headers: {
          Authorization: "Bearer " + access,
        },
      }
    );
  }

  getProposalDetail(access: string, TradingProposal_id: number) {
    return axios.get(
      API_ENDPOINTS.TradingProposal.TradingProposal + "/" + TradingProposal_id,
      {
        headers: {
          Authorization: "Bearer " + access,
        },
      }
    );
  }

  updateTradingProposal(
    TradingProposal_id: number,
    access: string,
    field: any
  ) {
    let data = 
    {"proposal_status": field.proposal_status}
    return axios.patch(
      API_ENDPOINTS.TradingProposal.TradingProposal + "/" + TradingProposal_id,
      data,
      {
        headers: {
          Authorization: "Bearer " + access,
        },
      }
    );
    }
  getTradingProposal(
    access: string,
    data: {
      postId: number;
      trading_asset: [{ asset_id: number; asset_quantity: number }];
      cash: number;
      quantity: number;
    }
  ) {
    return axios.post(API_ENDPOINTS.TradingProposal.TradingProposal, data, {
      headers: {
        Authorization: "Bearer " + access,
      },
    });
  }
}

export default new TradingProposal();
