









































































import { Vue, Component } from "vue-property-decorator";
import LogOut from "../../../components/profile/LogOut.vue";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");

@Component({
  components: {
    LogOut,
  },
})
export default class LandingPageHeader extends Vue {
  navbarStyle: { [key: string]: string | number } = {
    position: "fixed",
    top: 0,
    zIndex: 10,
    backgroundColor: "transparent",
  };

  showMobileMenu: boolean = false;

  @Auth.State("status")
  private UserIsLoggedIn!: any;

  @Auth.Action
  private signOut!: () => any;

  signIn() {
    this.$router.push("/landing-page/login");
  }

  logout() {
    this.$router.push("/landing-page/login");
    this.signOut();
  }

  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  }

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (window.scrollY > 0) {
      this.navbarStyle.backgroundColor = "#333"; // Change to your desired background color
    } else {
      this.navbarStyle.backgroundColor = "transparent"; // Revert to the initial background color
    }
  }
}
