



































































































import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "../../../../../globalUsage/Icon.vue";
@Component({
  components: { Icon },
})
export default class BuyNowWithoutPartialBidding extends Vue {
  dialog: false = false;
  @Prop() goNext!: void;
  @Prop() closeModal!: void;
}
