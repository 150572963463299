































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "../../../../globalUsage/Icon.vue";
import SellCoinsDefault from "./cards/SellCoinsDefault.vue";
import SellNowWithoutPartialBidding from "./cards/SellNowWithoutPartialBidding.vue";
import Confirmation from "./cards/Confirmation.vue";
import TradeRequested from "./cards/TradeRequested.vue";
import AmountToBuy from "./cards/AmountToBuy.vue";
import Success from "./cards/Success.vue";
import acceptingTradeRequest from "./cards/acceptingTradeRequest.vue";
import TradeRejected from "./cards/tradeRejected.vue";
import AConfirmation from "./cards/AConfirmation.vue";
import AddAmount from "../../../../postListing/addAmount.vue";
import SelectCoin from "./../selectCoin/SelectedCoin.vue";
import SuccessbuyMoney from "./cards/SuccessbuyMoney.vue";
import moment from "moment";
import { namespace } from "vuex-class";
import { eventBus } from "@/main";
import CheckBalance from "../../../../globalUsage/CheckBalance.vue";

const Auth = namespace("Auth");
const Investments = namespace("Investments");
const TradingAsset = namespace("TradingAsset");

@Component({
  components: {
    Icon,
    SellCoinsDefault,
    AmountToBuy,
    Confirmation,
    Success,
    AConfirmation,
    AddAmount,
    SelectCoin,
    SuccessbuyMoney,
    acceptingTradeRequest,
    SellNowWithoutPartialBidding,
    TradeRejected,
    TradeRequested,
    CheckBalance
  },
})
export default class BuyNow extends Vue {
  dialog: false = false;
  currentCard: number = 1;
  length: 4 = 4;
  count: number = 0;
  quantity: number = 0;
  showaconf: boolean = false;
  NoAsset: boolean = false;
  showconfirmation: boolean = false;
  toggleCoin: boolean = true;
  tradingIsClicked: boolean = false;
  buyTradingChoosen: boolean = false;
  buyMoneyChoosen: boolean = false;
  selection: boolean = false;
  sellPortion: boolean = false;
  addAmount: boolean = false;
  addQuantity: boolean = false;
  expiryModal: boolean = false;
  investmentAsset: any = [];
  selectedCoin: any = [];
  investmentMyAsset: Array<any> = [];
  error: string = "";
  loader: boolean = false;

  @Prop() post_type!: string;
  @Prop() data!: any;
  @Prop() id!: any;
  @Prop() userBalance!: any;
  get progress(): any {
    return (this.currentCard / this.length) * 100;
  }
  public goBack(): void {
    this.currentCard--;
  }
  public goNext(): void {
    this.currentCard++;
  }
  public closeModal(): any {
    this.dialog = false;
    location.reload();
  }
  // show confiramtion card
  public showCard(): any {
    this.showaconf = true;
    this.tradingIsClicked = false;
    this.toggleCoin = false;
    this.buyTradingChoosen = false;
    this.buyMoneyChoosen = true;
  }
  // hide confiramtion card
  public hideshowCard(): any {
    this.showaconf = false;
    this.toggleCoin = true;
    this.tradingIsClicked = true;
    this.buyTradingChoosen = true;
    this.buyMoneyChoosen = false;
  }
  // tradinguBy(): any {
  //   this.tradingIsClicked = true;
  // }
  // buyMoney(): any {
  //   this.tradingIsClicked = false;
  // }
  // showConf(): void {
  //   this.showconfirmation = true;
  // }
  // ToglleCoinShow(): any {
  //   this.toggleCoin = !this.toggleCoin;
  // }

  increment() {
    if (this.data.remaining_coins > this.count) {
      this.count++;
      this.addAmount = true;
    }
  }

  decrement() {
    if (this.count > 0) {
      this.count--;
    }
  }

  incrementQuantity() {
    if (this.data.remaining_coins > this.quantity) {
      this.quantity++;
      this.addQuantity = true;
    }
  }

  decrementQuantity() {
    if (this.quantity > 0) {
      this.quantity--;
    }
  }

  @Auth.Getter
  private userGetter!: any;

  @Investments.Action
  private buy!: (data: any) => Promise<any>;

  @Investments.Mutation
  private buyInvestmentsSuccess!: (success: any) => void;

  @Investments.Mutation
  private buyInvestmentsFailure!: (error: any) => void;

  @Investments.Action
  private getInvestments!: (data: any) => Promise<any>;

  @TradingAsset.Action
  private getTradingProposal!: (data: any) => Promise<any>;

  mounted() {
    // this.userGetter &&
    //   this.buy({
    //     asset_id: 1,
    //     asset_quantity: 1,
    //     access: this.userGetter.access
    //   })
    //     .then(res => this.buyInvestmentsSuccess(res.data))
    //     .catch(e => this.buyInvestmentsFailure(e));
  }

  filterUrl(url: any) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  getInvestmentAsset() {
    this.getInvestments({
      page: 1,
      page_size: 10,
      access: this.userGetter.access,
    }).then((res) => {
      if (res.data.results.length) {
        this.investmentAsset = [];
        this.investmentMyAsset = [];
        this.investmentMyAsset = this.investmentMyAsset.concat(
          res.data.results
        );
        res.data.results.forEach((element) => {
          this.data.accepted_coins.forEach((dataElement) => {
            if (dataElement.pk == element.asset.pk) {
              this.investmentAsset.push(element.asset);
            }
          });
        });
      }
    });
  }

  InvestmentAsset() {
    this.loader = true;
    let data = {
      postId: this.data.pk,
      trading_asset: [
        {
          asset_id: this.selectedCoin.pk,
          asset_quantity: this.quantity,
        },
      ],
      cash: this.data.assets_to_trade.price,
    };

    if (this.sellPortion) {
      data["quantity"] = this.count;
    } else {
      data["quantity"] = this.data.remaining_coins;
    }

    data["access"] = this.userGetter.access;

    this.getTradingProposal(data).then((res) => {
      if (res.status) {
        this.goNext();
      }
      this.loader = false;
    })
     .catch((e) =>{
      this.error = e[0]
      this.loader = false;
      setTimeout(() => {
          this.error = ""
      }, 5000 );
    });
  }

  NextButton() {
    this.investmentMyAsset.forEach((element) => {
      if (element.asset.pk == this.data.assets_to_trade.pk) {
        this.goNext();
      } else {
        this.NoAsset = true;
      }
    });
  }

  created() {
    this.getInvestmentAsset();
    eventBus.$on("selectedCoinEvent", (data: any) => {
      this.selectedCoin = data;
    });
  }

  clickSellPortion() {
    if (this.data.partial_binding == true) {
      this.sellPortion = true;
      this.goNext();
    }
  }

  compareDate(val: any) {
    let todayDate = moment().format("L, h:mm:ss a");
    let expiryDate = moment(val).format("L, h:mm:ss a");

    if (new Date(expiryDate) <= new Date(todayDate)) {
      this.expiryModal = true;
    }
  }
}
