

























































import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "../../globalUsage/Icon.vue";
import DatePicker from "./DatePicker.vue";
import Calendar from "./Calendar.vue";
@Component({
  components: { Icon, DatePicker, Calendar },
})
export default class MarketPlace extends Vue {

  @Prop() DateSelect!: any;
  @Prop() handleDate!: any;
  @Prop() date!: any;
  @Prop() handleDate1!: any;
  @Prop() date1!: any;
  @Prop() handleDate2!: any;
  @Prop() date2!: any;
  @Prop() setRangeClicked!: any;
  @Prop() selectDate!: boolean;
  @Prop() dateRange!: boolean;

  dialog: boolean = false;

  onClose(): any {
    this.dialog = false;
    // location.reload();
    this.$router.go(0);
    this.dateRange = false;
    this.selectDate = true;
  }

  setDateSelect(): any {
    this.dialog = !this.dialog;
    this.DateSelect = !this.DateSelect;
  }
  
}
