













































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Icon from "../globalUsage/Icon.vue";

@Component({
  components: { Icon },
})
export default class CoinDetail extends Vue {
  dialog: false = false;
  @Prop() seeDetails!: string;
  @Prop() referalDetails!: string;
  @Prop() yourCode!: string;
  @Prop() code!: string;
  @Prop() copyCode!: string;
  @Prop() shareThisCode!: string;
  @Prop() referalUsage!: string;
  @Prop() numberUsed!: string;
  @Prop() name!: string;
  @Prop() date!: string;
}
