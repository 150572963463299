


















































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import CoinDetail from "../../coinsDetails/CoinDetail.vue";
import SeeDetails from "../currentTab/see-details/SeeDetails.vue";

import Icon from "@/components/globalUsage/Icon.vue";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const Investments = namespace("Investments");

@Component({
  components: { CoinDetail, Icon, SeeDetails },
})
export default class CurrentProfileTab extends Vue {
  dialog: false = false;
  totalRecords: number = 0;
  page: number = 1;
  data: any = [];

  @Auth.Getter
  private userGetter!: any;

  @Investments.Action
  private getInvestments!: (data: any) => Promise<any>;

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  getProfitLoss(item) {
    let currentProfit =
      item.asset.price * item.purchased_quantity - item.total_investment;
    if (isNaN(currentProfit)) currentProfit = 0;

    let currentProfitPerc = (100 * currentProfit) / item.total_investment;
    if (isNaN(currentProfitPerc)) currentProfitPerc = 0;
    return Number(currentProfitPerc.toFixed(2));
  }

  getInvestmentsData(page: number) {
    this.getInvestments({
      access: this.userGetter.access,
      page: page,
      page_size: 100,
    }).then((response) => {
      this.page = page;
      if (response.data.count) {
        this.totalRecords = response.data.count;
      }
      if (response.data.results) {
        if (page == 1) {
          this.data = [];
        }
        this.data = this.data.concat(response.data.results);
      }
    });
  }

  created() {
    this.getInvestmentsData(1);
  }
}
