









































































































































































































































































































































































import moment from "moment";
import { Vue, Component, Watch } from "vue-property-decorator";
import acceptingTradeRequest from "../TradePortal/CoinDetail/pages/buyNow/cards/acceptingTradeRequest.vue";
import { namespace } from "vuex-class";
import RequestPage from "../globalUsage/RequestPage.vue";
const Auth = namespace("Auth");
const WalletService = namespace("Wallet");
const Users = namespace("Users");
const Notifications = namespace("Notifications");

@Component({
  components: {
    acceptingTradeRequest,
    RequestPage,
  },
})
export default class Header extends Vue {
  private showNotification: boolean = false;
  drawer: boolean = false;
  newNotification: boolean = true;
  data: any = [];
  items: Array<object> = [
    { title: "Click Me 1" },
    { title: "Click Me 2" },
    { title: "Click Me 3" },
    { title: "Click Me 4" },
  ];

  @Auth.State("status")
  private UserIsLoggedIn!: any;

  @WalletService.State("wallet")
  private userWallet!: any;

  @Auth.Action
  private signOut!: () => void;

  @Notifications.Action
  private getNotifications!: (data: any) => Promise<any>;

  @Auth.Getter
  private userGetter!: any;
  private isLoggedIn!: boolean;

  @Users.Getter
  private userProfileGetter!: any;

  @Watch("showNotification")
  onPropertyChanged(value: string) {
    value ? (this.newNotification = false) : "";
  }

  displayDate() {
    var date =
      this.userProfileGetter != null && this.userProfileGetter.data != null
        ? this.userProfileGetter.data.created_at
        : "";
    if (date != "" && date != null) {
      return new Date(date).toLocaleString("en-GB", {
        hour12: true,
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
    return "";
  }

  formatDate(val: string) {
    return moment(val).format('LLL');
  }

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  created() {
    this.getNotifications({ access: this.userGetter.access }).then((res) => {
      this.data = res.data;
    });
  }

  logOut() {
    this.signOut();
    this.$router.push("/");
  }
}
