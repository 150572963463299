






































































import ResetPasswordDialog from "./ResetPasswordDialog.vue";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const Users = namespace("Users");
const Wallet = namespace("Wallet");
const InitialCoinOffering = namespace("InitialCoinOffering");

import FormValidationRules from "@/mixins/FormValidationRules";

@Component({
  components: { ResetPasswordDialog },
})
export default class LoginForm extends Mixins(FormValidationRules) {
  showResetPassDialog: boolean = false;
  showPassword: boolean = false;
  lendingPage: boolean = false;
  APIError: string = "";
  private user: any = {
    email: "",
    password: "",
  };

  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.Action
  private login!: (data: any) => Promise<any>;

  @Auth.Mutation
  private loginFailure!: (error: any) => void;

  // need to be removed(fake success for Header UI)-------------------------
  @Auth.Mutation
  private loginSuccess!: (user: any) => any;

  @Auth.Getter
  private userGetter!: any;

  @Users.Action
  private getProfile!: (data: string) => Promise<any>;

  @Users.Mutation
  private getProfileFailure!: (error: any) => void;

  @Users.Mutation
  private getProfileSuccess!: (userProfile: any) => any;

  @Wallet.Action
  private get!: (data: any) => Promise<any>;

  @Wallet.Mutation
  private getWalletSuccess!: (wallet: any) => any;

  @InitialCoinOffering.Getter
  private icoIdGetter!: number | null;

  mounted() {
    // need to be removed(fake success for Header UI)-------------------------
    this.loginFailure(null);
    let url = new URL(window.location.href);
    if (url.pathname == "/landing-page/login") {
      this.lendingPage = true;
    }
  }

  @Watch("APIError")
  setAPIError(val): void {
    this.APIError = val.includes("401")
      ? "No active account found with the given credentials"
      : val;
  }

  validateLogIn() {
    let enteredDataStatusOk = (
      this.$refs.form as Vue & {
        validate: () => boolean;
      }
    ).validate();
    if (enteredDataStatusOk) {
      const self = this;

      this.$store.state.loader = true;
      self.login(self.user).then(
        (res) => {
          self.loginSuccess(res.data);
          // this.$router.push(`/account/${res.user_id}`);
          self.userGetter &&
            self
              .getProfile(self.userGetter)
              .then((resp) => {
                self.getProfileSuccess(resp.data);
                self.get({ access: self.userGetter.access }).then((res) => {
                  self.getWalletSuccess(res.data);
                  if(this.lendingPage){
                  this.icoIdGetter ? self.$router.push("/purchase-coin") : self.$router.push("/landing-page");
                  }
                  else {
                   self.$router.push("/dashboard/");
                  }
                });
              })
              .catch((e) => self.getProfileFailure(e));
          this.$store.state.loader = false;
        },
        (error) => {
          self.APIError = error;
          self.loginFailure(error);
          this.$store.state.loader = false;
        }
      );
    }
  }
}
