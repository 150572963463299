






import { Vue, Component,Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class Pagination extends Vue {
  // page: 1 = 1;
  @Prop() page!: number;

}
