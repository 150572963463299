



























































































































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const Users = namespace("Users");
import FormValidationRules from "@/mixins/FormValidationRules";

@Component
export default class SignUpForm extends Mixins(FormValidationRules) {
  private user: any = {
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    idDataSource: null,
    referralCode: null
  };
  showPassword: boolean = false;
  fileLoadErrorDialog: boolean = false;
  lendingPage: boolean = false;
  uploadedIdName: string = "";
  APIError: string = "";

  @Watch("APIError")
  setAPIError(val): void {
    this.APIError = val.includes("400")
      ? "user with this email already exists"
      : val;
  }

  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.Getter
  private userGetter!: any;

  @Auth.Action
  private register!: (data: any) => Promise<any>;

  @Auth.Action
  private login!: (data: any) => Promise<any>;

  @Auth.Mutation
  private loginSuccess!: (user: any) => any;

  // need to be removed(fake success for Header UI)-------------------------
  @Auth.Mutation
  private loginFailure!: (error: any) => void;

  @Auth.Mutation
  private registerSuccess!: (success: any) => void;

  @Auth.Mutation
  private registerFailure!: (error: any) => void;

  @Users.Action
  private create!: (data: any) => Promise<any>;

  @Users.Mutation
  private createUsersSuccess!: (success: any) => void;

  @Users.Mutation
  private createUsersFailure!: (error: any) => void;

  @Users.Action
  private getProfile!: (data: string) => Promise<any>;

  @Users.Mutation
  private getProfileFailure!: (error: any) => void;

  @Users.Mutation
  private getProfileSuccess!: (userProfile: any) => any;

  mounted() {
    // need to be removed(fake success for UI)-------------------------
    this.loginFailure(null);
    let url = new URL(window.location.href);
    if (url.pathname == "/landing-page/login") {
      this.lendingPage = true;
    }
  }

  validateSignUp() {
    let enteredDataStatusOk = (this.$refs.form as Vue & {
      validate: () => boolean;
    }).validate();
    if (enteredDataStatusOk) {
      // this.register(this.user).then(
      //   res => {
      //     this.registerSuccess(res);
      //     this.$router.push("/");
      //   },
      //   error => this.registerFailure(error)
      // );
      const self = this;
      this.$store.state.loader = true;
      self
        .create({
          ...self.user,
          identity_document: self.user.idDataSource,
          phone_number: self.user.phoneNumber,
          language: "en"
        })
        .then(res => {
          self.createUsersSuccess(res);
          self
            .login(self.user)
            .then(response => {
              self.loginSuccess(response.data);

              self.userGetter &&
                self
                  .getProfile(self.userGetter)
                  .then(resp => {
                    self.getProfileSuccess(resp.data);
                   if(this.lendingPage){
                   self.$router.push("/purchase-coin");
                   }
                   else {
                    self.$router.push("/account/");
                   }
                  })
                  .catch(e => self.getProfileFailure(e));
            })
            .catch(error => self.loginFailure(error));
          this.$store.state.loader = false;
        })
        .catch(err => {
          self.APIError = err;
          self.createUsersFailure(err);
          this.$store.state.loader = false;
        });
    }
  }

  onChangeId(event: any) {
    if (!event) {
      this.fileLoadErrorDialog = true;
    }
    this.uploadedIdName = event.name;
    let reader = new FileReader();
    // Use the javascript reader object to load the contents
    reader.readAsDataURL(event);

    reader.onerror = () => {
      this.fileLoadErrorDialog = true;
    };

    reader.onload = () => {
      this.user.idDataSource = reader.result;
    };
  }
}
