import axios from "axios";
import { API_ENDPOINTS } from "./ApiEndpoints";

const API_URL_2 = "https://replica.highpolar.in/v1/post-listing";

class PostListingService {
  buy(access: string, filter?: object, sort?: string) {
    return axios.get(API_ENDPOINTS.PostListing.Buy, {
      headers: {
        Authorization: "Bearer " + access,
      },
      params: {
        ...filter,
        sort,
      },
    });
  }

  sell(access: string, filter?: object, sort?: string) {
    return axios.get(API_ENDPOINTS.PostListing.Sell, {
      headers: {
        Authorization: "Bearer " + access,
      },
      params: {
        ...filter,
        sort,
      },
    });
  }

  create(
    access: string,
    data: {
      post_type: string;
      assets_to_trade: string;
      accepted_coins: string;
      partial_binding?: string;
      accepts_coin_trading?: string;
      accepts_money_transaction?: string;
      has_stop_condition?: string;
      expiry?: string;
      stop_loss_high?: string;
      stop_loss_low?: string;
    }
  ) {
    return axios.post(API_ENDPOINTS.PostListing.postListing, data, {
      headers: {
        Authorization: "Bearer " + access,
      },
    });
  }

  update(
    listing_id: string,
    post_type: string,
    assets_to_trade: string,
    accepted_coins: string,
    partial_binding: string,
    accepts_coin_trading: string,
    accepts_money_transaction: string,
    has_stop_condition: string,
    expiry: string,
    stop_loss_high: string,
    stop_loss_low: string,
    access: string
  ) {
    return axios.put(
      API_ENDPOINTS.PostListing.postListing + "/" + listing_id,
      {
        post_type,
        assets_to_trade,
        accepted_coins,
        partial_binding,
        accepts_coin_trading,
        accepts_money_transaction,
        has_stop_condition,
        expiry,
        stop_loss_high,
        stop_loss_low,
      },
      {
        headers: {
          Authorization: "Bearer " + access,
        },
      }
    );
  }

  getPostListing(
    access: string,
    page: number,
    page_size: number,
    filter?: object,
    sort?: string
  ) {
    return axios
      .get(API_ENDPOINTS.PostListing.postListing, {
        headers: {
          Authorization: "Bearer " + access,
        },
        params: {
          page,
          page_size,
          ...filter,
          sort,
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  getPostListingById(access: string, PostListing_id: number) {
    return axios.get(
      API_ENDPOINTS.PostListing.postListing + "/" + PostListing_id,
      {
        headers: {
          Authorization: "Bearer " + access,
        },
      }
    );
  }

  getMyListing(access: string) {
    return axios
      .get(API_ENDPOINTS.PostListing.mylistings, {
        headers: {
          Authorization: "Bearer " + access,
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  getMyListingPast(access: string) {
    return axios
      .get(API_ENDPOINTS.PostListing.mylistingsPast, {
        headers: {
          Authorization: "Bearer " + access,
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  getSuggestedListing(
    access: string,
    post_type: string,
    assets_to_trade: number,
    remaining_coins: number,
    accepted_coins: any
  ) {
    let url =
      API_ENDPOINTS.PostListing.postListing +
      "/suggested_listings?post_type=" +
      post_type +
      "&assets_to_trade=" +
      assets_to_trade +
      "&remaining_coins=" +
      remaining_coins;

    accepted_coins.forEach((element) => {
      url += "&accepted_coins=" + element;
    });

    return axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + access,
        },
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new PostListingService();
