
































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "../../../../../globalUsage/Icon.vue";
@Component({
  components: { Icon },
})
export default class AConfirmation extends Vue {
  dialog: false = false;
  @Prop() goNext!: void;
  @Prop() goBack!: void;
  @Prop() closeModal!: void;
  public ShowSelectBox(): void {
    const selectOption = document.querySelector(".selected-option") as any;
    selectOption.parentElement.classList.toggle("active");
    // list
    const options = document.querySelectorAll(".option") as any;

    options.forEach((option: any) => {
      option.addEventListener("click", () => {
        selectOption.innerHTML = option.innerHTML;
        selectOption.parentElement?.classList.remove("active");
      });
    });
  }
}
