














































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Icon from "../../../globalUsage/Icon.vue";
import moment from "moment";
@Component({
  components: { Icon },
})
export default class seelisting extends Vue {
  dialog: boolean = false;
  @Prop() data: any;
  @Prop() title!: string;
  dateTime() {
    return moment(this.data.posted_at).format("MMMM Do YYYY, hh:mm a");
  }
}
