





























































































































import { Vue, Prop, Component } from "vue-property-decorator";
import Icon from "../../globalUsage/Icon.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import SelectTime from "./SelectTime.vue";
@Component({
  components: {
    SelectTime,
    Icon,
    DatePicker,
  },
})
export default class Confirmation extends Vue {
  // @Emit();
  @Prop() goBack!: void;
  @Prop() closeModal!: void;
  @Prop() goNext!: void;
  @Prop() selectedCoin!: any;
  @Prop() count!: number;
  @Prop() changeAllowPartialBuy!: void;
  @Prop() changeAllowStopCondition!: void;

  allowPartialBuy: boolean = false;
  allowStopCondition: boolean = false;
  setAsRange: boolean = false;

  date: any = new Date();
  price: string = "";

  items: any = ["01.00", "01.15", "01.30", "01.45", "02.00", "02.15", "02.30"];
  selectTime: string = "01.00";

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }
}
