














































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import SeeDetail from "./SeeDetail.vue";
import Icon from "../../../globalUsage/Icon.vue";
import seelisting from "../seeListing/SeeListing.vue";
import { namespace } from "vuex-class";
import moment from "moment";
const Auth = namespace("Auth");
const PostListing = namespace("PostListing");

@Component({
  components: { SeeDetail, Icon, seelisting },
})
export default class ListingPastTab extends Vue {
  dialog: false = false;
  data: any = [];
  loader: boolean = true;
  page: number = 1;
  pageCount: number = 0;
  perPage: number = 10;
  paginatedData: any[] = [];

  @Auth.Getter
  private userGetter!: any;

  @PostListing.Action
  private getMyListingPast!: (access: string) => Promise<any>;

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  displayDateTime(value: any) {
    return moment(value).format("LL hh:mm");
  }

  displayDateTimePosted(value: any) {
    return moment(value).format("LL");
  }

  updatePagination() {
    const startIndex = (this.page - 1) * this.perPage;
    const endIndex = startIndex + this.perPage;
    this.paginatedData = this.data.slice(startIndex, endIndex);
  }

  changePage(newPage) {
    this.page = newPage;
    this.updatePagination();
  }

  created() {
    this.getMyListingPast(this.userGetter.access).then((response) => {
      if (response) {
        this.data = response;
        this.pageCount = Math.ceil(this.data.length / this.perPage);
        this.updatePagination();
      }
      this.loader = false;
    });
  }
}
