

































































































































































import { Vue, Component } from "vue-property-decorator";
// import Coin from "@/components/coin/Coin.vue";
// import PostListing from "@/components/postListing/PostListing.vue";
// import srcImg from "~/assets/icons/coin24gold.svg";
import All from "./assets/all/All.vue";
import Selling from "./assets/selling/Selling.vue";
import Buying from "./assets/buying/Buying.vue";
import Icon from "../../../globalUsage/Icon.vue";
import Sort from "./sort/Sort.vue";
import FilterCoin from "./filter/Filter.vue";
import Pagination from "./pagination/Pagination.vue";
import GraphMap from "./graphMap/GraphMap.vue";

import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const Assets = namespace("Assets");
const PostListing = namespace("PostListing");

@Component({
  components: {
    All,
    Selling,
    Buying,
    Icon,
    Sort,
    FilterCoin,
    Pagination,
    GraphMap,
  },
})
export default class TradingPortal extends Vue {
  myPortfolioTab: number = 0;
  // HeaderPage: string = "Trading portal";
  // BtnText: string = "Post listing";
  // @Prop() private gold24text!: String;

  data: any = [];
  dialog: boolean = false;
  partialBinding: boolean = false;
  loseTradeOnly: boolean = false;
  profitTradeOnly: boolean = false;
  availableForYourCoins: boolean = false;
  AssetsData: any = [];
  filterValue: any = [];
  selldata: any = [];
  buydata: any = [];
  pageCount: number = 0;
  BuyPageCount: number = 0;
  SellPageCount: number = 0;
  assetsName: Array<string> = [];
  filterItem: any;
  loading: boolean = true;
  userBalance: number = 0;

  page: number = 0;
  totalRecord: number = 0;
  PortfolioList: Array<string> = ["All", "Selling", "Buying"];
  SortData: object = {
    " Highest profit": "highest_profit",
    " Lowest profit": "lowest_profit",
    " Highest lose": "highest_lose",
    " Lowest lose": "lowest_lose",
    " Highest amount": "highest_amount",
    " Lowest amount": "lowest_amount",
    " Most coin types to trade": "most_coin_types_to_trade",
    " Highest remaining availablitys": "highest_remaining_availablity",
  };

  sortValue: string = "";

  filterData: object = {
    "Available for your coins": "available_for_your_coins",
    "Profit trade only": "profit_trade_only",
    "Lose trade only": "lose_trade_only",
    "Partial allowed": "true",
  };

  @Auth.Getter
  private userGetter!: any;

  @PostListing.Action
  private getPostListing!: (data: any) => Promise<any>;

  @PostListing.Action
  private buy!: (data: any) => Promise<any>;

  @PostListing.Action
  private sell!: (data: any) => Promise<any>;

  @Assets.Action
  private getAssets!: (data: any) => Promise<any>;

  @Auth.Action
  private getBalance!: (data: any) => Promise<any>;

  assetsNamefunction(value: any) {
    function findassets(fill: any) {
      return fill == value;
    }
    let valueAssets = this.assetsName.find(findassets);
    let valueindex = this.assetsName.indexOf(value);
    if (valueAssets == value) {
      // this.assetsName.pop()
      this.assetsName.splice(valueindex, 1);
    } else {
      this.assetsName.push(value);
    }
  }

  getfilterItem(value: any) {
    this.filterItem = value;
  }
  getSortValue(value: string) {
    this.sortValue = value;
  }

  onClose() {
    this.dialog = !this.dialog;
  }

  partialBindingfunction() {
    this.partialBinding = !this.partialBinding;
  }

  loseTradeOnlyfunction() {
    this.loseTradeOnly = !this.loseTradeOnly;
  }

  profitTradeOnlyfunction() {
    this.profitTradeOnly = !this.profitTradeOnly;
  }

  availableForYourCoinsfunction() {
    this.availableForYourCoins = !this.availableForYourCoins;
  }

  getFiletrData() {
    let filterObject = {};
    if (this.assetsName.length != 0) {
      filterObject["assets_to_trade__name"] = this.assetsName?.toString();
    }
    if (this.availableForYourCoins == true) {
      filterObject["accepted_coins"] = "True";
    }
    if (this.profitTradeOnly == true) {
      filterObject["profit_trade_only"] = this.profitTradeOnly;
    }
    if (this.loseTradeOnly == true) {
      filterObject["lose_trade_only"] = this.loseTradeOnly;
    }
    if (this.partialBinding == true) {
      filterObject["partial_binding"] = this.partialBinding;
    }
    if (this.myPortfolioTab === 0) {
      this.getPostListing({
        access: this.userGetter.access,
        page: 1,
        page_size: 100,
        filter: filterObject,
      }).then((response) => {
        this.data = [];

        this.data = this.data.concat(response.results);
        this.pageCount = Math.ceil(response.count / 10);

        if (response.status == 200) {
          this.onClose();
        }
      });
    }
    if (this.myPortfolioTab === 1) {
      this.sell({
        access: this.userGetter.access,
        filter: filterObject,
      }).then((response) => {
        this.selldata = response.data;
        this.SellPageCount = Math.ceil(response.data?.length / 10);

        if (response.status == 200) {
          this.onClose();
        }
      });
    }
    if (this.myPortfolioTab === 2) {
      this.buy({
        access: this.userGetter.access,
        filter: filterObject,
      }).then((response) => {
        this.buydata = response.data;
        this.BuyPageCount = Math.ceil(response.data?.length / 10);
        if (response.status == 200) {
          this.onClose();
        }
      });
    }
  }

  public Sortfunction() {
    if (this.myPortfolioTab === 0) {
      this.getPostListing({
        access: this.userGetter.access,
        page: 1,
        page_size: 100,
        sort: this.sortValue,
      }).then((response) => {
        this.data = [];
        this.data = this.data.concat(response.results);
        if (response.status) {
          this.onClose();
        }
      });
    }

    if (this.myPortfolioTab == 1) {
      this.sell({
        access: this.userGetter.access,
        sort: this.sortValue,
      }).then((response) => {
        this.selldata = response.data;
        this.SellPageCount = Math.ceil(response.data?.length / 10);
        if (response.status) {
          this.onClose();
        }
      });
    }

    if (this.myPortfolioTab == 2) {
      this.buy({
        access: this.userGetter.access,
        sort: this.sortValue,
      }).then((response) => {
        this.buydata = response.data;
        this.BuyPageCount = Math.ceil(response.data?.length / 10);
        if (response.status) {
          this.onClose();
        }
      });
    }
  }

  getPostListingData(page) {
    this.loading = true
    this.getPostListing({
      access: this.userGetter.access,
      page: page,
      page_size: 100,
    }).then((response) => {
      if (page == 1) {
        this.data = [];
      }
      this.data = this.data.concat(response.results);
      this.pageCount = Math.ceil(response.count / 10);
      this.loading = false
    });

    this.sell({
      access: this.userGetter.access,
    }).then((response) => {
      this.selldata = response.data;
      this.SellPageCount = Math.ceil(response.data?.length / 10);
    });

    this.buy({
      access: this.userGetter.access,
    }).then((response) => {
      this.buydata = response.data;
      this.BuyPageCount = Math.ceil(response.data?.length / 10);
    });
  }

  getAssetsData(page: number) {
    this.getAssets({
      access: this.userGetter.access,
      page: page,
      page_size: 12,
    }).then((response) => {
      if (response.data) {
        if (page == 1) {
          this.AssetsData = [];
        }
        this.AssetsData = this.AssetsData.concat(response.data.results);
      }
    });
  }

  created() {
    this.getPostListingData(1);
    this.getAssetsData(1);
     this.getBalance({
      access: this.userGetter.access,
    }).then((res) => {
      if (res) {
        this.userBalance = res.data.balance;
        // console.log("userBalance",this.userBalance)
      }
    });
  }
}
