








































































































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Icon from "../../../../globalUsage/Icon.vue";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const InvestmentOrders = namespace("InvestmentOrders");

@Component({
  components: { Icon }
})
export default class SeeDetails extends Vue {
  dialog: false = false;
  @Prop() data!: any;
  totalRecords: number = 0;
  page: number = 1;
  portfolioDetails: any = [];

  @Auth.Getter
  private userGetter!: any;

  @InvestmentOrders.Action
  private getInvestmentOrderDetailsByAsset!: (data: any) => Promise<any>;

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  displayDateTime(value: any) {
    if (value == null || value == "") return "";

    return new Date(value).toLocaleString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  getCoinProfitLoss() {
    let currentProfit =
      this.data.asset.price * this.data.purchased_quantity -
      this.data.total_investment;
    if (isNaN(currentProfit)) currentProfit = 0;

    let currentProfitPerc = (100 * currentProfit) / this.data.total_investment;
    if (isNaN(currentProfitPerc)) currentProfitPerc = 0;
    return Number(currentProfitPerc.toFixed(2));
  }

  getProfitLoss(item) {
    let currentProfit =
      this.data.asset.price * item.quantity - item.price * item.quantity;
    if (isNaN(currentProfit)) currentProfit = 0;

    let currentProfitPerc =
      (100 * currentProfit) / (this.data.asset.price * item.quantity);
    if (isNaN(currentProfitPerc)) currentProfitPerc = 0;
    return Number(currentProfitPerc.toFixed(2));
  }

  getDetails(page) {
    if (this.data.asset != null) {
      this.getInvestmentOrderDetailsByAsset({
        access: this.userGetter.access,
        page: page,
        page_size: 100,
        asset_id: this.data.asset.pk,
      }).then((response) => {
        this.page = page;
        if (response.data.count) {
          this.totalRecords = response.data.count;
        }
        if (response.data.results) {
          if (page == 1) {
            this.portfolioDetails = [];
          }
          this.portfolioDetails = this.portfolioDetails.concat(
            response.data.results
          );
        }
      });
    }
  }
}
