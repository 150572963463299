











































































import Icon from "../../globalUsage/Icon.vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import BuyNow from "../buyCoins/BuyBtn.vue";
import TradingVue from "trading-vue-js";
import { DataCube } from "trading-vue-js";
@Component({
  components: { Icon, BuyNow, TradingVue, DataCube },
})
export default class CoinStatus extends Vue {
  overlays: Array<object> = [];
  config: Object = {
    DEFAULT_LEN: 200,
    TB_BORDER: 5,
    CANDLEW: 0.9,
    GRIDX: 200,
    VOLSCALE: 0.5,
  };
  active: "1" = "1";
  baseDivWidth: any = this.GraphCWidth();
  baseDivHeight: any = this.GraphCHeight();
  chartWidth: number = this.baseDivWidth;
  chartHeight: number = this.baseDivHeight;
  noofChart: number = 1;
  @Prop() goBack!: void;
  @Prop() closeModal!: void;
  @Prop() asset!: any;
  @Prop() investmentsData!: void;
  @Prop() singlePointPriceGraphLiveData!: void;
  chart: Object = new DataCube(this.singlePointPriceGraphLiveData);

  mounted(): void {
    this.baseDivWidth = this.GraphCWidth();
    this.baseDivHeight = this.GraphCHeight();
  }

  GraphCWidth() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 200;
      case "sm":
        return 300;
      case "md":
        return 450;
      case "lg":
        return 750;
    }
  }

  GraphCHeight() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 200;
      case "sm":
        return 250;
      case "md":
        return 300;
      case "lg":
        return 350;
    }
  }

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }
}
