












































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Active from "../components/profile/listing/activeTab/Active.vue";
import ListingPastTab from "../components/profile/listing/pastTab/List.vue";
import PortfolioPast from "../components/profile/portfolio/activePast/Past.vue";
import CurrentProfileTab from "../components/profile/portfolio/currentTab/Current.vue";
import anotherCurrent from "../components/profile/portfolio/currentTab/current-with-dashboardbutton.vue";
import History from "../components/profile/history/History.vue";
import LogOut from "../components/profile/LogOut.vue";
import Wallet from "../components/profile/Wallet.vue";
import AccountDetails from "../components/globalUsage/AccountDetails.vue";
const Auth = namespace("Auth");
const Users = namespace("Users");
const WalletService = namespace("Wallet");

@Component({
  components: {
    Active,
    ListingPastTab,
    CurrentProfileTab,
    LogOut,
    Wallet,
    History,
    PortfolioPast,
    AccountDetails,
    anotherCurrent,
  },
})
export default class Profile extends Vue {
  myPortfolioTab: number = 0;
  myListingTab: number = 0;

  @Auth.Action
  private signOut!: () => any;

  @Auth.Getter
  private userGetter!: any;

  @Users.Getter
  private userProfileGetter!: any;

  @WalletService.State("wallet")
  private userWallet!: any;

  @Users.Action
  private getAll!: (data: string) => Promise<any>;

  @Users.Mutation
  private getUsersFailure!: (error: any) => void;

  @Users.Mutation
  private getUsersSuccess!: (userProfile: any) => any;

  logout() {
    this.$router.push("/");
    this.signOut();
  }

  mounted() {
    this.getAll(this.userGetter)
      .then((res) => this.getUsersSuccess(res.data))
      .catch((e) => this.getUsersFailure(e));
  }
}
