var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"userTransactions"},[_c('h2',[_vm._v("User Transactions")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tokenTransferTransactionData,"disableSort":"","hide-default-footer":"","page":_vm.page},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.transaction_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"item",attrs:{"dark":""}},[_c('h3',[_vm._v(_vm._s(item.transaction_id))]),_c('p',[_vm._v(_vm._s(_vm.formatDate(item.created_on)))])])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"item",attrs:{"dark":""}},[_c('h4',[_vm._v(_vm._s(item.quantity))]),_c('p',[_vm._v(_vm._s(item.asset_name))])])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.amount))])]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.sender_email))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"typeBox",attrs:{"dark":""}},[_vm._v(_vm._s(item.status))])]}}],null,true)}),_c('div',{staticClass:"pagination"},[_c('v-pagination',{staticClass:"my-4",attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }