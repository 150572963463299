



























































import { Component, Prop, Mixins } from "vue-property-decorator";
import FormValidationRules from "@/mixins/FormValidationRules";

@Component
export default class ResetPasswordDialog extends Mixins(FormValidationRules) {
  @Prop({ required: true }) value!: boolean;

  get show(): boolean {
    return this.value;
  }
  set show(newValue: boolean) {
    this.$emit("input", newValue);
  }
}
