




























































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AccountCreatedDialog from "@/components/account/AccountCreatedDialog.vue";
import Icon from "../components/globalUsage/Icon.vue";
import ReferalCode from "../components/account/ReferalCode.vue";
const Auth = namespace("Auth");
const Users = namespace("Users");
const WalletService = namespace("Wallet");

@Component({
  components: { AccountCreatedDialog, Icon, ReferalCode }
})
export default class Account extends Vue {
  myPortfolioTab: number = 0;
  myListingTab: number = 0;
  // showAccountCreatedDialog: boolean = true;
  @Auth.State("user")
  private currentUser!: any;

  @WalletService.State("wallet")
  private userWallet!: any;

  @Auth.Getter
  private isLoggedIn!: boolean;

  // need to be removed(fake success for UI)-------------------------
  @Auth.Mutation
  // private loginSuccess!: () => void;
  @Users.Getter
  private userProfileGetter!: any;

  mounted() {
    // need to be removed(fake success for Header UI)-------------------------
    // this.loginSuccess();
  }
}
