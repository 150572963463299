




















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

// Define the component in class-style
@Component
export default class Counter extends Vue {
  @Prop() count!: number;
  increment() {
    this.count++;
    // call hightlight function
    // this.Hightlight();
  }

  decrement() {
    if (this.count > 0) {
      this.count--;
    }
    // call hightlight function
    // this.Hightlight();
  }

  // //HighLight the button..
  // Hightlight() {
  //   const addHighlight = document.querySelectorAll(".addHighlight");

  //   function togglebtn(this: any) {
  //     this.classList.add("active");
  //   }
  //   // eslint-disable-next-line prettier/prettier
  //   addHighlight.forEach((btn) => btn.addEventListener("click", togglebtn));
  // }
}
