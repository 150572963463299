






import { Vue, Component, Prop } from "vue-property-decorator";
import Trade from "./TradeCoins.vue";

@Component({
  components: { Trade },
})
export default class TradeBtn extends Vue {
  @Prop() assetData!: any;
  // [x: string]: any;
  // @Prop() showCard!: any;
  // @Prop() Title!: string;
  // @Provide()
  // resetProcessConfirmed: boolean = false;
  // currentCard: 1 = 1;
  // length: 4 = 4;
  // get progress(): any {
  //   return (this.currentCard / this.length) * 100;
  // }
  // public goBack(): void {
  //   this.currentCard--;
  // }
  // public goNext(): void {
  //   this.currentCard++;
  // }
  // public closeModal(): any {
  //   return (this.dialog = false);
  // }
}
