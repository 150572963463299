





















































import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { eventBus } from "../../main";
const Auth = namespace("Auth");
const Assets = namespace("Assets");

@Component({})
export default class SelectCoin extends Vue {
  // data: any = [];
  totalRecords: number = 0;
  page: number = 1;
  // selectedCoin: any = null;
  @Prop() data:any;
  @Prop() selectedCoin :any = null;

  @Auth.Getter
  private userGetter!: any;

  @Assets.Action
  private getAssets!: (data: any) => Promise<any>;

  public ShowSelectBox(): void {
    const selectOption = document.querySelector(".selected-option") as any;
    selectOption.parentElement.classList.toggle("active");
    // list
    // const options = document.querySelectorAll(".option") as any;

    // options.forEach((option: any) => {
    //   option.addEventListener("click", () => {
    //     console.log(option);
    //     selectOption.innerHTML = option.innerHTML;
    //     selectOption.parentElement?.classList.remove("active");
    //   });
    // });
  }

  clickOption(item) {
    const selectOption = document.querySelector(".selected-option") as any;
    selectOption.parentElement.classList.toggle("active");
    this.selectedCoin = item;
     eventBus.$emit("selectedCoinEvent", item);
  }

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
   }
  }
