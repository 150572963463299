import axios from "axios";
import { API_ENDPOINTS } from "./ApiEndpoints";

const API_URL =
  "https://replica.highpolar.in/v1/assets";

class AssetsService {
  get(access: string) {
    // email: string, password: string
    return axios.get(API_ENDPOINTS.Assets.asset, {
      headers: {
        Authorization: "Bearer " + access
      }
      // params: {
      //   email,
      //   password
      // }
    });
  }

  getAssets(
    page: number,
    page_size: number,
    access: string
  ) {
    return axios.get(API_ENDPOINTS.Assets.asset, {
      headers: {
        Authorization: "Bearer " + access
      },
      params: {
        page,
        page_size,
      }
    });
  }

  getAssetById(
    asset_id: string,
    access: string
  ) {
    return axios.get(API_ENDPOINTS.Assets.asset + '/' + asset_id, {
      headers: {
        Authorization: "Bearer " + access
      }
    });
  }

  getAssetsLivePrice(
    access: string ,
    apiEndpoint?: any
  ) {
    return axios.get(apiEndpoint, {
      headers: {
        Authorization: "Bearer " + access
      }
    });
  }

  getAssetsLivePriceByTime(access: string, date?: object , apiEndpoint?: any) {
    return axios.get(apiEndpoint, {
      headers: {
        Authorization: "Bearer " + access,
      },
      params: {
        ...date
      },
    });
  }

  getAssetLiveDayPrice(asset_name: string, start_time: string , end_time: string) {
    return axios.post(API_ENDPOINTS.Assets.assetLivePrice, {
      asset_name,
      start_time,
      end_time
    });
  }

}

export default new AssetsService();