















































import { Vue, Component } from "vue-property-decorator";
import chooseCurrency from "../components/landing/cards/chooseCurrency.vue";
import userTransactions from "../components/landing/userTransactions/userTransactions.vue";
import LogOut from "../components/profile/LogOut.vue";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const TokenTransferTransaction = namespace("TokenTransferTransaction");

@Component({
  components: {
    chooseCurrency,
    userTransactions,
    LogOut,
  },
})
export default class PurchaseCoin extends Vue {
  transactionsPage: boolean = false;
  pageCount: number = 0;
  tokenTransferTransactionData: any = [];

  @Auth.State("status")
  private UserIsLoggedIn!: any;

  @Auth.Action
  private signOut!: () => any;

  @Auth.Getter
  private userGetter!: any;

  @TokenTransferTransaction.Action
  private getTokenTransferTransaction!: (data: any) => Promise<any>;

  getTokenTransferTransactionData() {
    this.getTokenTransferTransaction({
      access: this.userGetter.access,
    }).then((response) => {
      if (response.data) {
        this.tokenTransferTransactionData = response.data.results;
        this.pageCount = Math.ceil(
          this.tokenTransferTransactionData.length / 10
        );
      }
    });
  }

  signIn() {
    this.$router.push("/landing-page/login");
  }

  logout() {
    this.$router.push("/landing-page");
    this.signOut();
  }

  created() {
    this.getTokenTransferTransactionData();
  }
}
