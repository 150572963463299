




















































































































































import { Vue, Prop, Component } from "vue-property-decorator";
import Icon from "../../globalUsage/Icon.vue";
// import { eventBus } from "../../../main";
import SelectConversion from "./cards/SelectConversion.vue";
import SuggestedListings from "./cards/SuggestedListing.vue";
import ListingSuccessful from "./cards/ListingSuccessful.vue";
import moment from "moment";
import { POST_TYPE } from "../../../services/ApiEndpoints";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const PostListingService = namespace("PostListing");
import CheckBalance from "../../../components/globalUsage/CheckBalance.vue";

@Component({
  components: {
    Icon,
    SelectConversion,
    SuggestedListings,
    ListingSuccessful,
    CheckBalance,
  },
})
export default class TradeCoins extends Vue {
  dialog: boolean = false;
  AddAmount: boolean = false;
  resetProcessConfirmed: boolean = false;
  active: "1" = "1";
  postType: string = "";
  selectedCoin: any = {};
  count: number = 0;
  currentCard: number = 1;
  acceptedCoins: any = [];
  showSuggestListing: boolean = false;
  length: 4 = 4;
  stopHighPrice: number = 0;
  stopLowPrice: number = 0;
  allowPartialBidding: boolean = false;
  allowStopCondition: boolean = false;
  acceptCoinsTrading: boolean = true;
  date: any;
  time: string = "";
  expiryDate: any;
  access: string = "";
  userBalance: number = 0;
  loader: boolean = false;
  error: string = "";

  @Prop() Title!: string;
  @Prop() assetData!: any;

  public closeModal(): any {
    this.dialog = false;
    location.reload();
  }

  public showData(postType: string): any {
    this.postType = postType;
  }

  increment() {
    if (
      this.count < this.assetData.purchased_quantity ||
      this.postType == POST_TYPE.BUY
    ) {
      this.count++;
      this.AddAmount = true;
    }
  }

  decrement() {
    if (this.count > 0) {
      this.count--;
    }
  }

  public goBack(): void {
    this.currentCard--;
  }
  public goNext(): void {
    this.currentCard++;
  }

  public changeAllowPartialBidding(): void {
    this.allowPartialBidding = !this.allowPartialBidding;
  }

  public changeAllowStopCondition(): void {
    this.allowStopCondition = !this.allowStopCondition;
  }

  public stopHighCondition(value: number): void {
    this.stopHighPrice = value;
  }

  public stopLowCondition(value: number): void {
    this.stopLowPrice = value;
  }

  public handleExpiryDate(date): any {
    this.date = date;
  }
  public handleExpiryTime(time): any {
    this.time = time;
  }

  @Auth.Getter
  private userGetter!: any;

  @Auth.Action
  private getBalance!: (data: any) => Promise<any>;

  @PostListingService.Action
  private create!: (data: any) => Promise<any>;

  public createPostListing(): any {
    this.loader = true;
    let acceptedCoinsPrimaryKey: Array<string> = [];
    this.acceptedCoins.forEach((item) =>
      acceptedCoinsPrimaryKey.push(item.selectedCoin.pk)
    );

    let data = {
      has_stop_condition: this.allowStopCondition,
      post_type: this.postType,
      assets_to_trade: this.assetData.asset.pk,
      accepted_coins: acceptedCoinsPrimaryKey,
      accepts_money_transaction: false,
      accepts_coin_trading: this.acceptCoinsTrading,
      traded_coins: this.count,
      partial_binding: this.allowPartialBidding,
    };

    let date = moment(this.date).format("YYYY-MM-DD");
    this.expiryDate = new Date(date + "T" + this.time);
    data["expiry"] = this.expiryDate;
    data["stop_loss_high"] = this.stopHighPrice;
    data["stop_loss_low"] = this.stopLowPrice;

    // this.goNext();
    this.access = this.userGetter.access;
    data["access"] = this.access;

    this.create(data).then((res) => {
      if (res.status) {
        this.goNext();
      }
      this.loader = false;
    })
     .catch((e) =>{
      this.error = e
      this.loader = false;
      setTimeout(() => {
          this.error = ""
      }, 5000 );
    });
  }

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  created() {
    this.getBalance({
      access: this.userGetter.access,
    }).then((res) => {
      if (res) {
        this.userBalance = res.data.balance;
        // console.log("userBalance",this.userBalance)
      }
    });
    // eventBus.$on("selectCoin", (data: any) => {
    //   this.selectedCoin = data;
    // });
  }
}
