
























































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Icon from "../../../globalUsage/Icon.vue";
@Component({
  components: { Icon }
})
export default class CoinDetail extends Vue {
  dialog: false = false;
  @Prop() code!: string;
  @Prop() seeDetails!: string;
  @Prop() gold24!: string;
  @Prop() title!: string;
  @Prop() tradeDateTime!: string;
  @Prop() amount!: string;
  @Prop() numberCopper!: string;
  @Prop() coinsNumber!: string;
  @Prop() price!: string;
  @Prop() listingPostTab!: string;
  @Prop() tradWith!: string;
  @Prop() fullDateTime!: string;
  @Prop() closeIcon!: any;
  @Prop() src!: any;
}
