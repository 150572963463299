
































































































































































import { Vue, Prop, Component } from "vue-property-decorator";
import Icon from "../../globalUsage/Icon.vue";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const Assets = namespace("Assets");

@Component({
  components: {
    Icon,
  },
})
export default class SecoundCard extends Vue {
  @Prop() goBack!: void;
  @Prop() goNext!: Function;
  @Prop() closeModal!: void;
  @Prop() selectedCoin!: any;
  @Prop() acceptedCoins!: any;
  @Prop() count!: number;
  @Prop() postType!: string;
  @Prop() allAssets!: Array<any>;
  @Prop() myAssets!: Array<any>;

  showLoading: boolean = false;
  show: false = false;

  data: any = [];
  acceptedCoinsList: Array<any> = [];
  totalRecords: number = 0;
  page: number = 1;

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  @Auth.Getter
  private userGetter!: any;

  @Assets.Action
  private getAssets!: (data: any) => Promise<any>;

  addCoin() {
    this.acceptedCoinsList.push({
      selectedCoin: null,
      currentPrice: 0,
      currentProfit: 0,
      conversionCoin: 0,
      showCoinList: false,
      count: 0,
    });
  }

  Apply() {
    if (this.acceptedCoinsList.length) {
      this.acceptedCoinsList?.map((item) => {
        if (item.selectedCoin) {
          if (this.acceptedCoins.indexOf(item) === -1) {
            this.acceptedCoins.push(item);
          }
        }
      });
    }
    this.goNext();
  }

  removeCoin(index) {
    this.$delete(this.acceptedCoinsList, index);
    this.$delete(this.acceptedCoins, index);
    this.setConversionCoin();
  }

  clickCoin(item, index) {
    this.acceptedCoinsList[index].selectedCoin = item;
    this.acceptedCoinsList[index].showCoinList = false;
    this.setConversionCoin();
  }

  increment(index) {
    this.acceptedCoins[index].count++;
  }

  decrement(index) {
    if (this.acceptedCoins[index].count > 0) {
      this.acceptedCoins[index].count--;
    }
  }

  setConversionCoin() {
    let amount = 0;
    if (this.acceptedCoinsList.length > 0) {
      amount =
        (this.selectedCoin.price * this.count) / this.acceptedCoinsList.length;
      if (isNaN(amount)) amount = 0;

      this.acceptedCoinsList.forEach((element) => {
        let conversionCoin = 0;
        if (element.selectedCoin.price != 0) {
          conversionCoin = amount / element.selectedCoin.price;
          if (isNaN(conversionCoin)) conversionCoin = 0;
        }

        element.currentPrice =
          element.selectedCoin.price < 0
            ? "-$" + element.selectedCoin.price * -1
            : "$" + element.selectedCoin.price;
        element.currentProfit = 0;
        element.conversionCoin = Number(conversionCoin.toFixed(2));
      });
    }
  }

  mounted() {
    if (this.acceptedCoins?.length) {
      this.acceptedCoins.filter((item) => {
        if (this.acceptedCoinsList.indexOf(item) === -1) {
          this.acceptedCoinsList.push(item);
        }
      });
    }
    if (this.postType == "SELL") {
      // buy assets
      this.allAssets.forEach((element) => {
        if (this.selectedCoin.pk != element.pk) {
          this.data.push(element);
        }
      });
    }
    if (this.postType == "BUY") {
      this.myAssets.forEach((element) => {
        if (this.selectedCoin.pk != element.pk) {
          this.data.push(element);
        }
      });
    }
  }
}
