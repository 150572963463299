











































































































import { Vue, Prop, Component } from "vue-property-decorator";
import NoSuggestedListing from "./NoSuggestedListing.vue";
import Icon from "../../../globalUsage/Icon.vue";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const Investments = namespace("Investments");
const PostListing = namespace("PostListing");

@Component({
  components: {
    Icon,
    NoSuggestedListing,
  },
})
export default class SuggestedListings extends Vue {
  // @Emit();
  @Prop() goBack!: void;
  @Prop() closeModal!: void;
  @Prop() goNext!: void;
  @Prop() postType!: string;
  @Prop() selectedCoin!: any;
  @Prop() acceptedCoins!: any;
  @Prop() count!: number;
  @Prop() changeAllowPartialBidding!: void;
  @Prop() changeAllowStopCondition!: void;
  @Prop() assetData!: any;
  @Prop() stopHighPrice!: string;
  @Prop() stopHighCondition!: void;
  @Prop() stopLowPrice!: string;
  @Prop() stopLowCondition!: void;
  @Prop() time!: string;
  @Prop() date!: string;
  @Prop() handleExpiryDate!: void;
  @Prop() handleExpiryTime!: void;
  @Prop() loader!: any;
  @Prop() error!: any;

  suggestedList: any = [];

  @Auth.Getter
  private userGetter!: any;

  @Investments.Action
  private totalInvestmentByAsset!: (data: any) => Promise<any>;

  @PostListing.Action
  private getSuggestedListing!: (data: any) => Promise<any>;

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  mounted() {
    this.totalInvestmentByAsset({
      asset_id: this.assetData.asset.pk,
      access: this.userGetter.access,
    }).then((response) => {
      if (response.data.length > 0) {
        var accepted_coins: any = [];
        this.acceptedCoins.forEach((element) => {
          accepted_coins.push(element.selectedCoin.pk);
        });
        this.getSuggestedListing({
          access: this.userGetter.access,
          post_type: this.postType,
          assets_to_trade: this.assetData.asset.pk,
          // remaining_coins: response.data[0].purchased_quantity - this.count,
          remaining_coins: 1,
          accepted_coins: accepted_coins,
        }).then((result) => {
          this.suggestedList = result;
          this.suggestedList = [];
        });
      }
    });
  }
}
