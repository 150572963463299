import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import UsersService from "@/services/UsersService";

const storedUsers = localStorage.getItem("users");
const storedUserProfile = localStorage.getItem("userProfile");

@Module({ namespaced: true })
class Users extends VuexModule {
  public users = storedUsers ? JSON.parse(storedUsers) : null;
  public userProfile = storedUserProfile ? JSON.parse(storedUserProfile) : null;

  get userProfileGetter() {
    return this.userProfile;
  }

  get usersGetter() {
    return this.users;
  }

  @Mutation
  public getUsersSuccess(users: any): void {
    this.users = users;
    localStorage.setItem("users", JSON.stringify(users));
  }

  @Mutation
  public getUsersFailure(error: any): void {
    this.users = null;
  }

  @Mutation
  public getProfileSuccess(userProfile: any): void {
    this.userProfile = userProfile;
    localStorage.setItem("userProfile", JSON.stringify(userProfile));
  }

  @Mutation
  public getProfileFailure(error: any): void {
    this.userProfile = null;
  }

  @Mutation
  public createUsersSuccess(users: any): void {
    this.users = users;
    localStorage.setItem("users", JSON.stringify(users));
  }

  @Mutation
  public createUsersFailure(error: any): void {
    console.error(error);
    this.users = null;
  }

  @Mutation
  public createCopyUsersSuccess(users: any): void {
    this.users = users;
    localStorage.setItem("users", JSON.stringify(users));
  }

  @Mutation
  public createCopyUsersFailure(error: any): void {
    console.error(error);
    this.users = null;
  }

  @Mutation
  public changePasswordSuccess(response: any): void {
    console.log("change success", response);
  }

  @Mutation
  public changePasswordFailure(error: any): void {
    console.error(error);
  }

  
  @Mutation
  public EmailSuccess(response: any): void {
    console.log("send success", response);
  }

  @Mutation
  public EmailSendFailure(error: any): void {
    console.error(error);
  }

  @Mutation
  public updateProfileSuccess(userProfile: any): void {
    this.userProfile = userProfile;
    localStorage.setItem("userProfile", JSON.stringify(userProfile));
  }

  @Mutation
  public updateProfileFailure(error: any): void {
    console.error(error);
    this.userProfile = null;
  }

  @Action({ rawError: true })
  getAll(data: any): Promise<any> {
    return UsersService.getAll(data.access).then(
      // data.email, data.password
      users => {
        this.context.commit("getUsersSuccess", users);
        return Promise.resolve(users);
      },
      error => {
        this.context.commit("getUsersFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  create(data: any): Promise<any> {
    return UsersService.create(
      data.name,
      data.email,
      data.password,
      data.phone_number,
      data.language,
      data.identity_document,
      data.referral_code
    ).then(
      users => {
        this.context.commit("createUsersSuccess", users);
        return Promise.resolve(users);
      },
      error => {
        this.context.commit("createUsersFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  createCopy(data: any): Promise<any> {
    return UsersService.createCopy(data.name, data.email, data.password).then(
      users => {
        this.context.commit("createCopyUsersSuccess", users);
        return Promise.resolve(users);
      },
      error => {
        this.context.commit("createCopyUsersFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  getProfile(data: any): Promise<any> {
    return UsersService.getProfile(data.user_id, data.access).then(
      userProfile => {
        this.context.commit("getProfileSuccess", userProfile);
        return Promise.resolve(userProfile);
      },
      error => {
        this.context.commit("getProfileFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  changePassword(data: any): Promise<any> {
    return UsersService.changePassword(data).then(
      changedSuccess => {
        this.context.commit("changePasswordSuccess", changedSuccess);
        return Promise.resolve(changedSuccess);
      },
      error => {
        this.context.commit("changePasswordFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  forgotPassword(data: any): Promise<any> {
    return UsersService.forgotPassword(data.email).then(
      EmailSendSuccess => {
        this.context.commit("EmailSuccess",  EmailSendSuccess);
        return Promise.resolve( EmailSendSuccess);
      },
      error => {
        this.context.commit("EmailSendFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  updateProfile(data: any): Promise<any> {
    return UsersService.updateProfile(
      data.user_id,
      data.access,
      data.field
    ).then(
      (userProfile) => {
        this.context.commit("updateProfileSuccess", userProfile);
        return Promise.resolve(userProfile);
      },
      (error) => {
        if(error.response) {
          // this.context.commit("updateProfileFailure");
          return Promise.reject(error.response.data);
        }
      }
    )
  }
}

export default Users;
