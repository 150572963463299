import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import AssetsService from "@/services/AssetsService";

const storedAssets = localStorage.getItem("assets");
const storedLiveData = localStorage.getItem("liveData");
@Module({ namespaced: true })
class Assets extends VuexModule {
  public assets = storedAssets ? JSON.parse(storedAssets) : null;
  public liveData = storedLiveData ? JSON.parse(storedLiveData) : null;

  get userGetter() {
    return this.liveData;
  }

  @Mutation
  public assetLivePriceSuccess(liveData: any): void {
    this.liveData = liveData;
    localStorage.setItem("liveData", JSON.stringify(liveData));
  }

  @Mutation
  public getAssetsSuccess(assets: any): void {
    this.assets = assets;
    localStorage.setItem("assets", JSON.stringify(assets));
  }

  @Mutation
  public getAssetsFailure(error: any): void {
    console.error(error);
    this.assets = null;
  }

  @Action({ rawError: true })
  get(data: any): Promise<any> {
    // data.email, data.password
    return AssetsService.get(data.access).then(
      assets => {
        this.context.commit("getAssetsSuccess", assets);
        return Promise.resolve(assets);
      },
      error => {
        this.context.commit("getAssetsFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  getAssets(data: any): Promise<any> {
    return AssetsService.getAssets(
      data.page,
      data.page_size,
      data.access
    ).then(
      response => {
        return Promise.resolve(response);
      },
      error => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  getAssetById(data: any): Promise<any> {
    return AssetsService.getAssetById(
      data.asset_id,
      data.access
    ).then(
      response => {
        return Promise.resolve(response);
      },
      error => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  getAssetsLivePrice(data: any): Promise<any> {
    return AssetsService.getAssetsLivePrice(
      data.access ,
      data.apiEndpoint
    ).then(
      response => {
        return Promise.resolve(response);
      },
      error => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  getAssetsLivePriceByTime(data: any): Promise<any> {
    return AssetsService.getAssetsLivePriceByTime(data.access, data.date , data.apiEndpoint ).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  getAssetLiveDayPrice(data: any): Promise<any> {
    return AssetsService.getAssetLiveDayPrice(data.asset_name, data.start_time,data.end_time).then(
      response => {
        this.context.commit("assetLivePriceSuccess", response);
        return Promise.resolve(response);
      },
      error => {
        this.context.commit("assetLivePriceFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }


}

export default Assets;