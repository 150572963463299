import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import NotificationsService from "@/services/NotificationsService";

const storedNotifications = localStorage.getItem("notifications");

@Module({ namespaced: true })
class Notifications extends VuexModule {
  public notifications = storedNotifications ? JSON.parse(storedNotifications) : null;

  @Mutation
  public getNotificationsSuccess(notifications: any): void {
    this.notifications = notifications;
  }

  @Mutation
  public getNotificationsFailure(): void {
    this.notifications = null;
  }

  @Action({ rawError: true })
  getNotifications(data: any): Promise<any> {
    return NotificationsService.getNotifications(data.access).then(
        notifications => {
        this.context.commit("getNotificationsSuccess", notifications);
        return Promise.resolve(notifications);
      },
      error => {
        this.context.commit("getNotificationsFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
}

export default Notifications;
