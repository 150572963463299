










































import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "../../../../globalUsage/Icon.vue";
@Component({
  components: { Icon },
})
export default class Sort extends Vue {
  // dialog: false = false;
  isSelected: false = false;
  active: "1" = "1";
  @Prop() SortData!: any;
  @Prop() Sortfunction!: any;
  @Prop() getSortValue!: any;
  @Prop() dialog!: boolean;
}
