












import Vue from "vue";
import Component from "vue-class-component";
import Header from "./components/globalUsage/Header.vue";

@Component({
  components: { Header },
})
export default class App extends Vue {
  lendingPage: boolean = true;
  mounted() {
    let url = new URL(window.location.href);
    switch (url.pathname) {
      case "/landing-page":
      case "/purchase-coin":
      case "/landing-page/login":
      case "/landing-page/":
      case "/purchase-coin/":
        this.lendingPage = true;
        break;
      default:
        this.lendingPage = false;
        break;
    }
  }
}
