import { VuexModule, Module, Action } from "vuex-module-decorators";
import InvestmentOrderService from "@/services/InvestmentOrderService";

@Module({ namespaced: true })
class InvestmentOrders extends VuexModule {

  @Action({ rawError: true })
  getInvestmentOrders(data: any): Promise<any> {
    return InvestmentOrderService.getInvestmentOrders(
      data.page,
      data.page_size,
      data.access
    ).then(
      response => {
        return Promise.resolve(response);
      },
      error => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  getInvestmentOrderDetails(data: any): Promise<any> {
    return InvestmentOrderService.getInvestmentOrderDetails(
      data.id,
      data.access).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return Promise.reject(message);
        }
      );
  }

  @Action({ rawError: true })
  getInvestmentOrdersPast(data: any): Promise<any> {
    return InvestmentOrderService.getInvestmentOrdersPast(
      data.page,
      data.page_size,
      data.access
    ).then(
      response => {
        return Promise.resolve(response);
      },
      error => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  getInvestmentOrderPastDetails(data: any): Promise<any> {
    return InvestmentOrderService.getInvestmentOrderPastDetails(
      data.id,
      data.access).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return Promise.reject(message);
        }
      );
  }

  @Action({ rawError: true })
  getInvestmentOrderDetailsByAsset(data: any): Promise<any> {
    return InvestmentOrderService.getInvestmentOrderDetailsByAsset(
      data.asset_id,
      data.page,
      data.page_size,
      data.access).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return Promise.reject(message);
        }
      );
  }
}

export default InvestmentOrders;
