import axios from "axios";
import { API_ENDPOINTS } from "./ApiEndpoints";

class InitialCoinOfferingService {
  getInitialCoinOffering() {
    return axios.get(API_ENDPOINTS.InitialCoinOffering.InitialCoinOffering, {});
  }
  getBiddingAssetQuantityCount(ico?: string) {
    return axios.get(API_ENDPOINTS.InitialCoinOffering.BiddingAssetQuantityCount, {
      headers: {},
      params: {
        ico: ico
      },
    });
  }
  createBidding(
    access: string,
    data: {
      quantity: number;
      asset: number;
      ico: number;
    }
  ) {
    return axios.post(API_ENDPOINTS.InitialCoinOffering.createBidding, data, {
      headers: {
        Authorization: "Bearer " + access,
      },
    });
  }

  payWithPaypal(
    access: string,
    data: {
      bidding_id: number;
    }
  ) {
    return axios.post(API_ENDPOINTS.PayWithPaypal.PayWithPaypal,
       data,
        {
      headers: {
        Authorization: "Bearer " + access,
      },
    });
  }
}

export default new InitialCoinOfferingService();