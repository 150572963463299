


































































import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "../../../../../globalUsage/Icon.vue";
import AddAmount from "../../../../../postListing/addAmount.vue";
import BTN from "../../../../../globalUsage/Button.vue";
import SelectCoin from "../../../../../selectCoin/SelectCoin.vue";
@Component({
  components: { Icon, AddAmount, BTN, SelectCoin },
})
export default class Sort extends Vue {
  @Prop() goBack!: void;
  @Prop() goNext!: void;
  @Prop() closeModal!: void;
  @Prop() showCard!: void;
  @Prop() showConf!: void;
  toggleCoin: boolean = true;
  selection: boolean = false;
  isSelected: false = false;
  active: "1" = "1";

  // public ToglleCoinShow(): any {
  //   return (this.toggleCoin = !this.toggleCoin);
  // }
  ToglleCoinShow(): any {
    this.toggleCoin = !this.toggleCoin;
    this.showCard;
  }
  ShowSelectBox(): any {
    // return (this.toggleCoin = true);
    this.showConf;
  }
  // public changeContent(): void {
  //   const selectOption = document.querySelector(
  //     ".selected-option"
  //   )! as HTMLElement;
  //   const options = (document.querySelectorAll(
  //     ".option"
  //   )! as unknown) as HTMLElement;

  //   options.forEach((option: any) => {
  //     option.addEventListener("click", () => {
  //       selectOption.innerHTML = option.innerHTML;
  //     });
  //   });
  //   console.log("working");
  // }
}
