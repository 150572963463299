






































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const Assets = namespace("Assets");
const InitialCoinOffering = namespace("InitialCoinOffering");
import SelectCoin from "../../postListing/selectCoin/SelectedCoin.vue";
import { eventBus } from "../../../main";

@Component({
  components: {
    SelectCoin,
  },
})
export default class ChooseCurrency extends Vue {
  selectCurrencyStyle: { [key: string]: string | number } = {
    border: `2px solid white`,
  };

  ethCurrency: boolean = false;
  usdCurrency: boolean = false;
  data: any = [];
  selectedCoin: any = {};
  items: any = [];
  totalAmount: number = 0;
  quantity: string = "";
  errorMessage: string = "";
  loader: boolean = false;
  link: string = "";
  biddingId: string = "";

  @Assets.Action
  private getAssets!: (data: any) => Promise<any>;

  @InitialCoinOffering.Getter
  private icoIdGetter!: number | null;

  @InitialCoinOffering.Action
  private payWithPaypal!: (data) => Promise<any>;

  @Auth.Getter
  private userGetter!: any;

  @InitialCoinOffering.Action
  private createBidding!: (data) => Promise<any>;

  @Watch("selectedCoin")
  onSelectedCoinChanged(newValue) {
    this.totalAmount = newValue.price * Number(this.quantity);
  }
  @Watch("quantity")
  onQuantityChanged(newValue) {
    this.totalAmount = newValue * this.selectedCoin.price;
  }

  selectETHcurrency() {
    this.ethCurrency = true;
    this.usdCurrency = false;
  }

  selectUSDcurrency() {
    this.usdCurrency = true;
    this.ethCurrency = false;
  }

  getAssetsData(page: number) {
    this.getAssets({
      access: this.userGetter.access,
      page: page,
      page_size: 100,
    }).then((response) => {
      if (response.data.results) {
        this.items = [];
        this.items = response.data.results;
      }
    });
  }

  payment() {
    let data = {
      bidding_id: this.biddingId,
    };
    data["access"] = this.userGetter.access;

    this.payWithPaypal(data)
      .then((res) => {
        if (res) {
          res.data.links.forEach((element) => {
            if (element.rel == "approve") {
              this.link = element.href;
              window.open(this.link, "_self");
            }
          });
        }
        // this.loader = false;
      })
      .catch((error) => {
        this.loader = false;
        this.errorMessage = error;
        setTimeout(() => (this.errorMessage = ""), 5000);
      });
  }

  createBiddingPay() {
    this.loader = true;
    let data = {
      quantity: this.quantity,
      asset: this.selectedCoin.pk,
      ico: this.icoIdGetter,
    };
    data["access"] = this.userGetter.access;

    this.createBidding(data)
      .then((res) => {
        if (res) {
          this.totalAmount = res.data.amount;
          this.biddingId = res.data.id;
          this.payment();
        }
        // this.loader = false;
      })
      .catch((error) => {
        this.loader = false;
        this.errorMessage = error;
        setTimeout(() => (this.errorMessage = ""), 5000);
      });
  }

  created() {
    this.getAssetsData(1);

    eventBus.$on("selectedCoinEvent", (data: any) => {
      this.selectedCoin = data;
    });
  }
}
