





















import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Service extends Vue {
  @Prop() item!: any;
  getImagePath(filename) {
    return require(`@/assets/icons/${filename}`);
  }
}
