
























import { Vue, Component } from "vue-property-decorator";
import Icon from "../../globalUsage/Icon.vue";
import SetAuto from "./SetAuto.vue";
import ConditionSet from "./ConditionSet.vue";
@Component({
  components: {
    Icon,
    SetAuto,
    ConditionSet,
  },
})
export default class SellConfirmation extends Vue {
  currentScreen: number = 1;
  length: 2 = 2;
  dialog: false = false;
  setRangeNotClicked: boolean = true;
  showManulaRange: boolean = false;

  get progress(): any {
    return (this.currentScreen / this.length) * 100;
  }
  public goNext(): void {
    this.currentScreen++;
  }
  public closeModal(): any {
    return (this.dialog = false);
  }
  setRangeClicked(): any {
    // return (this.setRangeNotClicked = !this.setRangeNotClicked);
    // this.showManulaRange = true;

    this.setRangeNotClicked = !this.setRangeNotClicked;
    this.showManulaRange = !this.showManulaRange;
    // this.setRangeNotClicked = true;
  }
  // @Emit();
}
