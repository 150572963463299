








































































































import { Component, Prop, Vue } from "vue-property-decorator";
// import { eventBus } from "../../../main";

@Component({})
export default class SelectCoin extends Vue {
  data: any = [
    { time: "01:00", selected: false },
    { time: "01:15", selected: false },
    { time: "01:30", selected: false },
    { time: "01:45", selected: false },
    { time: "02:00", selected: false },
    { time: "02:15", selected: false },
    { time: "02:30", selected: false },
  ];
  @Prop() handleExpiryTime!: Function;
  @Prop() time!: string;
  selectedTime: string = this.time;

  public ShowSelectBox(): void {
    const selectOption = document.querySelector(".selected-option") as any;
    selectOption.parentElement.classList.toggle("active");
  }
  closeDropDown() {
    const selectOption = document.querySelector(".selected-option") as any;
    selectOption.parentElement.classList.toggle("active");
  }

  clickOption(item) {
    this.data.forEach((element) => {
      element.selected = false;
    });
    item.selected = true;
  }

  setTime() {
    const selectOption = document.querySelector(".selected-option") as any;
    selectOption.parentElement.classList.toggle("active");
    this.data.forEach((element) => {
      if (element.selected) {
        this.selectedTime = element.time;
        this.handleExpiryTime(element.time);
      }
    });
  }
}
