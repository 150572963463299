import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import TradingService from "@/services/TradingService";

@Module({ namespaced: true })
class Trading extends VuexModule {

    @Action({ rawError: true })
    getTrading(data: any): Promise<any> {
        return TradingService.getTrading(
            data.page,
            data.page_size,
            data.access
        ).then(
            response => {
                return Promise.resolve(response);
            },
            error => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                return Promise.reject(message);
            }
        );
    }
}

export default Trading;
