import axios from "axios";
import { API_ENDPOINTS } from "./ApiEndpoints";


const API_URL =
  "https://replica.highpolar.in/v1/investment_orders";

class InvestmentOrdersService {

  getInvestmentOrders(
    page: number,
    page_size: number,
    access: string
  ) {
    return axios.get(API_ENDPOINTS.InvestmentOrders.investmentOrders, {
      headers: {
        Authorization: "Bearer " + access
      },
      params: {
        page,
        page_size,
      }
    });
  }

  getInvestmentOrderDetails(
    id: number,
    access: string
  ) {
    return axios.get(API_ENDPOINTS.InvestmentOrders.investmentOrders + '/' + id, {
      headers: {
        Authorization: "Bearer " + access
      },
    });
  }

  getInvestmentOrdersPast(
    page: number,
    page_size: number,
    access: string
  ) {
    return axios.get(API_ENDPOINTS.InvestmentOrders.investmentOrdersPast, {
      headers: {
        Authorization: "Bearer " + access
      },
      params: {
        page,
        page_size,
      }
    });
  }

  getInvestmentOrderPastDetails(
    id: number,
    access: string
  ) {
    return axios.get(API_ENDPOINTS.InvestmentOrders.investmentOrdersPastId + '/' + id, {
      headers: {
        Authorization: "Bearer " + access
      },
    });
  }

  getInvestmentOrderDetailsByAsset(
    asset_id: number,
    page: number,
    page_size: number,
    access: string
  ) {
    return axios.get(API_ENDPOINTS.InvestmentOrders.investmentOrders, {
      headers: {
        Authorization: "Bearer " + access
      },
      params: {
        asset_id: asset_id,
        page: page,
        page_size: page_size,
      }
    });
  }
}

export default new InvestmentOrdersService();
