



































































































import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "../../../../../globalUsage/Icon.vue";
@Component({
  components: { Icon },
})
export default class BuyNow extends Vue {
  dialog: false = false;
  @Prop() goNext!: any;
  @Prop() closeModal!: void;
  @Prop() data!: any;
  @Prop() clickBuyPortion!: any;
  @Prop() expiryModal!: boolean;

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }
}
