

































































































import { Vue, Component, Prop } from "vue-property-decorator";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import SelectTime from "../../postListing/cards/SelectTime.vue";
import Icon from "../../globalUsage/Icon.vue";
@Component({
  components: {
    Icon,DatePicker,SelectTime
  },
})
export default class SellConfirmation extends Vue {
  @Prop() closeModal!: void;
  @Prop() goNext!: void;
  setRangeNotClicked: boolean = true;
  date: any = new Date();
  showManulaRange: boolean = false;

  setRangeClicked(): any {
    this.setRangeNotClicked = !this.setRangeNotClicked;
    this.showManulaRange = !this.showManulaRange;
  }
}
