


































import { Vue, Component, Prop } from "vue-property-decorator";
import SellNow from "../../SellNow/SellNow.vue";
import Icon from "../../../../../globalUsage/Icon.vue";
import SeeDetail from "../seedetail/SeeDetails.vue"

@Component({
  components: { Icon ,SellNow, SeeDetail}
})
export default class Selling extends Vue {
  myPortfolioTab: number = 0;
  myListingTab: number = 0;
  HeaderPage: string = "Trading portal";
  BtnText: string = "Post listing";

  @Prop() postData!: any;
  @Prop() page!: number;
  @Prop() getPostListingData!: (page: number) => Promise<any>;
  @Prop() userBalance!: any;

  headers: any = [
    {
      text: "ID",
      align: "start",
      sortable: false,
      value: "pk",
    },
    { text: "Amount", value: "traded_coins" ,align: "center"},
    { text: "Trade with", align: "center", value: "accepted_coins" },
    { text: "Possible profit/lose", value: "-" ,align: "center"},
    { text: "Prices", value: "total_price" ,align: "center"},
    { text: "Remaining availability", value: "remaining_coins",align: "center" },
    { text: "", value: "post_type" ,align: "center"},
    { text: "", value: "SeeMoreButton",align: "center" },
  ];

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }
}
