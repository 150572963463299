




































































































import { Vue, Prop, Component } from "vue-property-decorator";
import Icon from "../../globalUsage/Icon.vue";
@Component({
  components: {
    Icon
  }
})
export default class SellConfirmation extends Vue {
  @Prop() goBack!: void;
  @Prop() goNext!: void;
  @Prop() closeModal!: void;

  // @Emit();
}
