import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import TradingProposal from "@/services/TradingProposal";

const storedTradingAsset = localStorage.getItem("TradingAsset");

@Module({ namespaced: true })
class TradingAsset extends VuexModule {
  public TradingAsset = storedTradingAsset
    ? JSON.parse(storedTradingAsset)
    : null;

    public userTradingAsset = storedTradingAsset ? JSON.parse(storedTradingAsset) : null;

    get userTradingGetter() {
      return this.userTradingAsset;
    }

    @Mutation
    public updateTradingProposalSuccess(userTradingAsset: any): void {
      this.userTradingAsset = userTradingAsset;
      localStorage.setItem("userTradingAsset", JSON.stringify(userTradingAsset));
    }

    @Action({ rawError: true })
    getProposal(data: any): Promise<any> {
      return TradingProposal.getProposal(
        data.access
      ).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
         const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return Promise.reject(message);
        }
      );
      }

  @Action({ rawError: true })
  getProposalDetail(data: any): Promise<any> {
    return TradingProposal.getProposalDetail(
      data.access,
      data.TradingProposal_id
    ).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
       const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
    }
  @Mutation
  public createPostListingSuccess(TradingAsset: any): void {
    this.TradingAsset = TradingAsset;
  }

  @Mutation
  public createPostListingFailure(): void {
    this.TradingAsset = null;
  }

  @Action({ rawError: true })
  getTradingProposal(data: {
    access: string;
    postId: number;
    trading_asset: [{ asset_id: number; asset_quantity: number }];
    cash: number;
    quantity: number;
  }): Promise<any> {
    return TradingProposal.getTradingProposal(data.access, data).then(
      (TradingAsset) => {
        this.context.commit("createPostListingSuccess", TradingAsset);
        return Promise.resolve(TradingAsset);
      },
      (error) => {
        if(error.response) {
          return Promise.reject(error.response.data);
        }
      }
    );
  }

  @Action({ rawError: true })
  updateTradingProposal(data: any): Promise<any> {
    return TradingProposal.updateTradingProposal(
      data.TradingProposal_id,
      data.access,
      data.field
    ).then(
      (userTradingAsset) => {
        this.context.commit("updateTradingProposalSuccess", userTradingAsset);
        return Promise.resolve(userTradingAsset);
      },
      (error) => {
        if(error.response) {
          // this.context.commit("updateProfileFailure");
          return Promise.reject(error.response.data);
        }
      }
    )
  }
}

export default TradingAsset;
