var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.postData,"page":_vm.page,"disableSort":"","hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.accepted_coins",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"img"},_vm._l((item.accepted_coins),function(el,i){return _c('v-img',{key:i,staticClass:"mx-1",staticStyle:{"max-width":"25px","max-height":"25px"},attrs:{"src":el.icon}})}),1)]}},{key:"item.post_type",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.post_type == 'SELL')?_c('BuyNow',{attrs:{"id":index,"data":item,"userBalance":_vm.userBalance}}):_vm._e(),(item.post_type == 'BUY')?_c('SellNow',{attrs:{"userBalance":_vm.userBalance,"data":item}}):_vm._e()]}},{key:"item.SeeMoreButton",fn:function(ref){
var item = ref.item;
return [_c('SeeDetail',{attrs:{"data":item},model:{value:(item.SeeMoreButton),callback:function ($$v) {_vm.$set(item, "SeeMoreButton", $$v)},expression:"item.SeeMoreButton"}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }