import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// const API_URL =
//   "ws://8vitlyjnp0.execute-api.me-south-1.amazonaws.com/virtualcoin/ws/v1/asset";
// const userCreds = localStorage.getItem("user");
// console.log(userCreds);
// const Socket = new WebSocket(`
//   ${API_URL}?token=${userCreds && JSON.parse(userCreds).access}`);

// Socket.addEventListener("open", event => {
//   Socket.send("Hello Server!");
// });

export default new Vuex.Store({
  state: {
    connect: false,
    message: null
  },
  mutations: {
    SOCKET_CONNECT: (state, status) => {
      state.connect = true;
    },
    SOCKET_USER_MESSAGE: (state, message) => {
      state.message = message;
    }
  },
  actions: {
    otherAction: (context, type) => {
      return true;
    },
    socket_userMessage: (context, message) => {
      context.dispatch("newMessage", message);
      context.commit("NEW_MESSAGE_RECEIVED", message);
      if (message.is_important) {
        context.dispatch("alertImportantMessage", message);
      }
    }
  }
});
