





















































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { eventBus } from "../../../main";
const Auth = namespace("Auth");
const Assets = namespace("Assets");

@Component({})
export default class SelectCoin extends Vue {
  @Prop() data!: Array<any>;
  totalRecords: number = 0;
  page: number = 1;
  @Prop() selectedCoin: any;

  @Auth.Getter
  private userGetter!: any;

  @Assets.Action
  private getAssets!: (data: any) => Promise<any>;

  public ShowSelectBox(): void {
    const selectOption = document.querySelector(".selected-option") as any;
    selectOption.parentElement.classList.toggle("active");
    // list
    // const options = document.querySelectorAll(".option") as any;

    // options.forEach((option: any) => {
    //   option.addEventListener("click", () => {
    //     console.log(option);
    //     selectOption.innerHTML = option.innerHTML;
    //     selectOption.parentElement?.classList.remove("active");
    //   });
    // });
  }

  clickOption(item) {
    const selectOption = document.querySelector(".selected-option") as any;
    selectOption.parentElement.classList.toggle("active");
    this.selectedCoin = item;
    eventBus.$emit("selectedCoinEvent", item);
  }

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  // getAssetsData(page: number) {
  //   this.getAssets({
  //     access: this.userGetter.access,
  //     page: page,
  //     page_size: 100,
  //   }).then((response) => {
  //     this.page = page;
  //     if (response.data.count) {
  //       this.totalRecords = response.data.count;
  //     }
  //     if (response.data.results) {
  //       if (page == 1) {
  //         this.data = [];
  //       }
  //       this.data = this.data.concat(response.data.results);
  //     }
  //   });
  // }

  // created() {
  //   this.getAssetsData(1);
  // }
}
