
























import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
  components: { DatePicker },
})
export default class dataPicker extends Vue {
  @Prop() handleDate!: any;
  @Prop() date!: any;
}
