





























import { Vue, Prop, Component } from "vue-property-decorator";
@Component({})
export default class AcceptCoinsOrTransaction extends Vue {
  @Prop() changeAcceptCoinsTrading!: void;
  @Prop() changeAccpetMoneyTrasaction!: void;
}
