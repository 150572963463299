import Vue from "vue";
import VueRouter from "vue-router";
import Auth from "../views/Auth.vue";
import Account from "../views/Account.vue";
///pages..
import TradingPortal from "../views/TradingPortal.vue";
import Profile from "../views/Profile.vue";
import AccountCreated from "../views/AccountCreated.vue";
import CoinDetails from "../components/TradePortal/CoinDetail/pages/CoinDetails.vue";
//Dashboard
import Dashboard from "../views/Dashboard.vue";
import LandingPage from "@/views/LandingPage.vue";
import PurchaseCoin from "@/views/PurchaseCoin.vue"
import PaymentSuccess from "@/views/PaymentSuccess.vue"
import PaymentFailed from "@/views/PaymentFailed.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Auth
  },
  { path: "/account/", component: Account },
  {
    path: "/profile",
    name: "profile",
    component: Profile
  },

  // otherwise redirect to login
  { path: "*", redirect: "/" },
  {
    path: "/trading-portal",
    name: "trading-portal",
    component: TradingPortal
    // children: [
    //   {
    //     path: "/coin-details",
    //     name: "coin-details",
    //     component: CoinDetails
    //   }
    // ]
  },
  {
    path: "/account-created",
    name: "account-created",
    component: AccountCreated
  },
  {
    path: "/payment-success",
    name: "payment-success",
    component: PaymentSuccess
  },
  {
    path: "/payment-failed",
    name: "payment-failed",
    component: PaymentFailed
  },
  {
    path: "/coin-details/:asset_id",
    name: "coin-details",
    component: CoinDetails,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard
  },
  {
    path: "/landing-page",
    name: "landing-page",
    component: LandingPage
  },
  {
    path: "/purchase-coin",
    name: "purchase-coin",
    component: PurchaseCoin
  },
  {
    path: "/landing-page/login",
    name: "Login",
    component: Auth
  },
  // {
  //   // path: "/about",
  //   // name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   // component: () =>
  //   //   import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Scroll to the top when navigating to a new page
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const loggedIn = true; // localStorage.getItem("user")

  if (to.path !== "/" && !loggedIn) {
    return next("/");
  }

  next();
});

export default router;
