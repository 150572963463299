

























































import { Vue, Prop, Component } from "vue-property-decorator";
import Icon from "../../globalUsage/Icon.vue";
@Component({
  components: {
    Icon,
  },
})
export default class SecoundCard extends Vue {
  @Prop() closeModal!: void;
  @Prop() data!: Array<any>;
  @Prop() response!: any;
  @Prop() postType!: string;

  coin: any;

  handleCoinListing() {
    this.data.forEach((element) => {
      if (element.pk == this.response.assets_to_trade) {
        this.coin = element;
      }
    });
  }

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  created() {
    this.handleCoinListing();
  }
}
