
























import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class Coin extends Vue {
  @Prop() text!: string;
  @Prop() subtext!: string;
  @Prop() price!: string;
  @Prop() srcImg!: any;
}
