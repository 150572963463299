import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import TokenTransferTransactionService from "@/services/TokenTransferTransactionService";

const storedTokenTransferTransaction = localStorage.getItem("tokenTransferTransaction");

@Module({ namespaced: true })
class TokenTransferTransaction extends VuexModule {
    public tokenTransferTransaction = storedTokenTransferTransaction ? JSON.parse(storedTokenTransferTransaction) : null;

    get tokenTransferTransactionGetter() {
        return this.tokenTransferTransaction;
    }

    @Mutation
    public getTokenTransferTransactionSuccess(tokenTransferTransaction: any): void {
        this.tokenTransferTransaction = tokenTransferTransaction;
        localStorage.setItem("tokenTransferTransaction", JSON.stringify(tokenTransferTransaction));
    }

    @Mutation
    public getTokenTransferTransactionFailure(error: any): void {
        console.error(error);
        this.tokenTransferTransaction = null;
    }

    @Action({ rawError: true })
    getTokenTransferTransaction(data: any): Promise<any> {
        return TokenTransferTransactionService.getTokenTransferTransaction(data.access).then(
            tokenTransferTransaction => {
                this.context.commit("getTokenTransferTransactionSuccess", tokenTransferTransaction);
                return Promise.resolve(tokenTransferTransaction);
            },
            error => {
                this.context.commit("getTokenTransferTransactionFailure");
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                return Promise.reject(message);
            }
        );
    }
}

export default TokenTransferTransaction;
