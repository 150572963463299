import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import ProfileService from "@/services/ProfileService";

const storedProfile = localStorage.getItem("Profile");

@Module({ namespaced: true })
class Profile extends VuexModule {
  public Profile = storedProfile ? JSON.parse(storedProfile) : null;

  @Mutation
  public getProfileSuccess(Profile: any): void {
    this.Profile = Profile;
  }

  @Mutation
  public getProfileFailure(): void {
    this.Profile = null;
  }

  @Action({ rawError: true })
  getProfile(data: any): Promise<any> {
    return ProfileService.getProfile(data.access).then(
        Profile => {
        this.context.commit("getProfileSuccess", Profile);
        return Promise.resolve(Profile);
      },
      error => {
        this.context.commit("getProfileFailure");
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
}

export default Profile;