













































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AccountCreatedDialog from "@/components/account/AccountCreatedDialog.vue";
import ReferalCode from "../components/account/ReferalCode.vue";
import LogOut from "../components/profile/LogOut.vue";
import History from "../components/profile/history/History.vue";
import AccountDetails from "../components/globalUsage/AccountDetails.vue";
import Wallet from "../components/profile/Wallet.vue";
import anotherCurrent from "../components/profile/portfolio/currentTab/current-with-dashboardbutton.vue";
import PortfolioPast from "../components/profile/portfolio/activePast/Past.vue";
import ListingPastTab from "../components/profile/listing/pastTab/List.vue";
import Active from "../components/profile/listing/activeTab/Active.vue";
import CurrentProfileTab from "../components/profile/portfolio/currentTab/Current.vue";

const Auth = namespace("Auth");
const Users = namespace("Users");
const WalletService = namespace("Wallet");

@Component({
  components: {
    AccountCreatedDialog,
    ReferalCode,
    LogOut,
    History,
    AccountDetails,
    Wallet,
    anotherCurrent,
    PortfolioPast,
    ListingPastTab,
    Active,
    CurrentProfileTab,
  },
})
export default class Account extends Vue {
  myPortfolioTab: number = 0;
  myListingTab: number = 0;
  showAccountCreatedDialog: boolean = false;

  @Auth.State("user")
  private currentUser!: any;

  @Auth.Action
  private signOut!: () => any;

  @WalletService.State("wallet")
  private userWallet!: any;

  @Auth.Getter
  private isLoggedIn!: boolean;

  @Users.Getter
  private userProfileGetter!: any;

  // need to be removed(fake success for UI)-------------------------
  @Auth.Mutation
  private loginSuccess!: () => void;

  logout() {
    this.$router.push("/");
    this.signOut();
  }

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  mounted() {
    var showNotification = localStorage.getItem(
      "accountCreateNotificationShow"
    );
    if (showNotification == null) {
      this.showAccountCreatedDialog = true;
      localStorage.setItem("accountCreateNotificationShow", "true");
    } else {
      var createdAt =
        this.userProfileGetter != null && this.userProfileGetter.data != null
          ? new Date(this.userProfileGetter.data.created_at)
          : null;
      var currentDate = new Date();
      if (showNotification == null && createdAt != null) {
        var diffDays = createdAt.getDate() - currentDate.getDate();
        if (diffDays == 0) {
          this.showAccountCreatedDialog = true;
          localStorage.setItem("accountCreateNotificationShow", "true");
        }
      }
    }
  }
}
