






















import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "../globalUsage/Icon.vue";
import { namespace } from "vuex-class";
const TradingAsset = namespace("TradingAsset");
const Auth = namespace("Auth");
const Assets = namespace("Assets");
const PostListing = namespace("PostListing");
@Component({
  components: { Icon },
})
export default class TradeRequest extends Vue {
  @Prop() closeModal!: any;
  @Prop() showNotification!: boolean;
  @Prop() trading_proposal!: number;
  @Prop() title!: string;
  dialog: boolean = false;
  tradingProposalData: any = {};
  tradingAsset: any = {};
  PostListingData: any = {};
  TradingProposalStatus: string = "";

  onClose() {
    this.showNotification = false;
    this.dialog = false;
  }

  @TradingAsset.Action
  private getProposalDetail!: (data: any) => Promise<any>;

  @PostListing.Action
  private getPostListingById!: (data: any) => Promise<any>;

  @Assets.Action
  private getAssetById!: (data: any) => Promise<any>;

  @TradingAsset.Action
  private updateTradingProposal!: (data: any) => Promise<any>;

  @Auth.Getter
  private userGetter!: any;

  TradingAsset(id: number) {
    this.getAssetById({
      asset_id: id,
      access: this.userGetter.access,
    }).then((response) => {
      if (response.data) {
        this.tradingAsset = response.data;
      }
    });
  }

  PostListingById(id: number) {
    this.getPostListingById({
      access: this.userGetter.access,
      PostListing_id: id,
    }).then((response) => {
      if (response.data) {
        this.PostListingData = response.data;
      }
    });
  }

  TradingProposalCreated(id: number) {
    this.getProposalDetail({
      access: this.userGetter.access,
      TradingProposal_id: id,
    }).then((response) => {
      if (response.data) {
        this.tradingProposalData = response.data;

        this.TradingAsset(response.data.trading_asset[0].asset_id);
        this.PostListingById(response.data.postId);
      }
    });
  }

  proposalStatus(id: number, status: string) {
    this.updateTradingProposal({
      TradingProposal_id: id,
      access: this.userGetter.access,
      field: {
        proposal_status: status,
      },
    });
    this.onClose();
  }

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }
}
