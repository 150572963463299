































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import seelisting from "../seeListing/SeeListing.vue";
import Pagination from "../../../TradePortal/CoinDetail/pages/pagination/Pagination.vue";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
const PostListing = namespace("PostListing");
const TradingAsset = namespace("TradingAsset");

@Component({
  components: { seelisting, Pagination },
})
export default class ListingPastTab extends Vue {
  dialog: false = false;
  data: any = [];
  proposalData: any = [];
  loader: boolean = true;
  page: number = 1;
  pageCount: number = 0;
  perPage: number = 10;
  paginatedData: any[] = [];

  @Auth.Getter
  private userGetter!: any;

  @TradingAsset.Action
  private getProposal!: (data: any) => Promise<any>;

  @PostListing.Action
  private getMyListing!: (access: string) => Promise<any>;

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }

  displayDateTime(value: any) {
    if (value == null || value == "") return "";

    return new Date(value).toLocaleString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  getTradingProposal() {
    this.getProposal({
      access: this.userGetter.access,
    }).then((response) => {
      if (response.data) {
        this.proposalData = response.data;
      }
    });
  }

  printProposalStatusData(id: string) {
    let data = this.proposalData.filter((element) => {
      if (element.postId == id) {
        return element;
      }
    });
    if (data.length) {
      return data[0].proposal_status;
    } else return null;
  }

  checkItemIdInProposalData(id: string) {
    let data = this.proposalData.filter((element) => {
      if (element.postId == id) {
        return element;
      }
    });
    if (data.length) return true;
    return false;
  }

  updatePagination() {
    const startIndex = (this.page - 1) * this.perPage;
    const endIndex = startIndex + this.perPage;
    this.paginatedData = this.data.slice(startIndex, endIndex);
  }

  changePage(newPage) {
    this.page = newPage;
    this.updatePagination();
  }

  created() {
    this.getTradingProposal();
    this.getMyListing(this.userGetter.access).then((response) => {
      if (response) {
        this.data = response;
        this.pageCount = Math.ceil(this.data.length / this.perPage);
        this.updatePagination();
      }
      this.loader = false;
    });
  }
}
