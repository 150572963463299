













































// import Vue from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";

import Icon from "../../globalUsage/Icon.vue";

@Component({
  components: { Icon },
})
export default class DataPint extends Vue {
  @Prop() closeModal!: void;
}
