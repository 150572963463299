import axios from "axios";
import { API_ENDPOINTS } from "./ApiEndpoints";

class TokenTransferTransactionService {
  getTokenTransferTransaction(access: string) {
    return axios.get(API_ENDPOINTS.TokenTransferTransaction.tokenTransferTransaction, {
      headers: {
        Authorization: "Bearer " + access
      }
    });
  }
}

export default new TokenTransferTransactionService();
