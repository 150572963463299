


































import { Vue, Component, Prop } from "vue-property-decorator";
import BuyNow from "../../buyNow/BuyNow.vue";
// import Coin from "@/components/coin/Coin.vue";
// import PostListing from "@/components/postListing/PostListing.vue";
// import srcImg from "~/assets/icons/coin24gold.svg";
import Icon from "../../../../../globalUsage/Icon.vue";
import SeeDetail from "../seedetail/SeeDetails.vue"

@Component({
  components: { Icon , BuyNow, SeeDetail}
})
export default class Selling extends Vue {
  headers: any = [
    {
      text: "ID",
      align: "start",
      sortable: false,
      value: "pk",
    },
    { text: "Amount", value: "traded_coins",align: "center" },
    { text: "Trade with", align: "center", value: "accepted_coins" },
    { text: "Possible profit/lose", value: "-",align: "center" },
    { text: "Prices", value: "total_price" ,align: "center"},
    { text: "Remaining availability", value: "remaining_coins" ,align: "center"},
    { text: "", value: "post_type",align: "center" },
    { text: "", value: "SeeMoreButton",align: "center" },
  ];

  @Prop() postData!: any;
  @Prop() page!: number;
  @Prop() getPostListingData!: (page: number) => Promise<any>;
  @Prop() userBalance!: any;

  filterUrl(url) {
    if (url != null && url != "") {
      return url.split("?")[0];
    }
    return "";
  }
}
